import { Link, useParams } from "react-router-dom";
import { useGetStoryQuery } from "../../apis/stories";
import BreadCrumbs from "../../atoms/BreadCrumbs";
import { buildSrcSet, formatDate } from "../../utils";
import css from "./Story.module.css";
import sanitizeHtml from "sanitize-html";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import Spinner from "../../atoms/Spinner";
import PageTitle from "../../atoms/PageTitle";
import IFramePage from "../../templates/IFramePage";

const { REACT_APP_API_ENDPOINT } = process.env;

function Story() {
  const { storyId } = useParams();
  if (!storyId || typeof storyId !== "string")
    throw new Error("Страница вне роутера");

  const { data, isLoading, isError } = useGetStoryQuery(parseInt(storyId));

  if (data?.data.attributes.link)
    return (
      <IFramePage
        title={data.data.attributes.title}
        src={data.data.attributes.link}
      />
    );

  return (
    <NewDesignBasicPage>
      <article className={css.wrapper}>
        <BreadCrumbs
          className={css.breadCrumbs}
          items={[
            <Link to="/stories">Герои регионов</Link>,
            data?.data.attributes.title,
          ]}
        />
        <PageTitle className={css.title}>
          {data?.data.attributes.title}
        </PageTitle>
        <div className={css.inner}>
          {isLoading ? (
            <Spinner />
          ) : isError ? (
            <span>Что-то пошло не так</span>
          ) : (
            <>
              <div className={css.thumbnail}>
                <img
                  src={`${REACT_APP_API_ENDPOINT}${data?.data.attributes.thumbnail?.data.attributes.url.substring(
                    1
                  )}`}
                  alt={data?.data.attributes.thumbnail?.data.attributes.caption}
                  srcSet={
                    data?.data.attributes.thumbnail?.data.attributes
                      ? buildSrcSet(
                          data.data.attributes.thumbnail.data.attributes
                        )
                      : undefined
                  }
                />
              </div>

              <div
                className={css.content}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(data?.data.attributes.content || ""),
                }}
              ></div>
              <p className={css.date}>
                {formatDate(new Date(data?.data.attributes.createdAt || ""))}
              </p>
            </>
          )}
        </div>
      </article>
    </NewDesignBasicPage>
  );
}

export default Story;
