import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  FindResult,
  StrapiPhoto,
  StrapiVideo,
  WithPagination,
} from "../strapi-types";

export type Photo = {
  image: { data: FindResult<StrapiPhoto> };
  title: string;
  createdAt: string;
};
export type FoundPhotos = FindResult<Photo>;

export type Video = {
  thumbnail: { data: FindResult<StrapiPhoto> };
  title: string;
  description: string;
  views: number;
  video: { data: FindResult<StrapiVideo> };
  createdAt: string;
};

export type FoundVideos = FindResult<Omit<Video, "video">>;

export const mediaAPI = createApi({
  reducerPath: "mediaAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}api`,
  }),
  endpoints: (builder) => ({
    getPhotos: builder.query<
      WithPagination<FoundPhotos>,
      { start: number; limit: number }
    >({
      query: (params) =>
        `/photos?sort[0]=createdAt:desc&pagination[start]=${params.start}&pagination[limit]=${params.limit}&populate[1]=image&fields[1]=title&fields[2]=createdAt`,
    }),
    getVideos: builder.query<
      WithPagination<FoundVideos>,
      { start: number; limit: number }
    >({
      query: (params) =>
        `/videos?sort[0]=createdAt:desc&pagination[start]=${params.start}&pagination[limit]=${params.limit}&populate[1]=thumbnail&populate[2]=video&fields[1]=title&fields[2]=views&fields[3]=description&fields[4]=createdAt`,
    }),
    getVideo: builder.query<{ data: FindResult<Video> }, string>({
      query: (id) => `/videos/${id}?populate[1]=video&populate[2]=thumbnail`,
    }),
  }),
});

export const {
  useLazyGetPhotosQuery,
  useLazyGetVideosQuery,
  useGetVideoQuery,
} = mediaAPI;
