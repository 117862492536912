import css from "./IFramePage.module.css";

interface IIFramePageProps {
  src: string;
  title: string;
}

function IFramePage({ src, title }: IIFramePageProps) {
  return <iframe src={src} title={title} className={css.wrapper} />;
}

export default IFramePage;
