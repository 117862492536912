import { Link, useLocation } from "react-router-dom";
import { buildComplexClassName } from "../../utils";
import css from "./MediaTabsHeader.module.css";

enum MediaPage {
  Photo,
  Video,
}

interface IMediaTabsHeaderProps {
  className?: string;
}

function MediaTabsHeader({ className }: IMediaTabsHeaderProps) {
  const { pathname } = useLocation();
  const activeSection = pathname.startsWith("/video")
    ? MediaPage.Video
    : MediaPage.Photo;
  return (
    <div className={buildComplexClassName(css.wrapper, className)}>
      <div className={css.content}>
        <Link
          aria-disabled={activeSection === MediaPage.Photo}
          className={buildComplexClassName(
            css.link,
            activeSection === MediaPage.Photo && css.active
          )}
          to="/photo"
        >
          Фото
        </Link>
        <Link
          aria-disabled={activeSection === MediaPage.Video}
          className={buildComplexClassName(
            css.link,
            activeSection === MediaPage.Video && css.active
          )}
          to="/video"
        >
          Видео
        </Link>
      </div>
    </div>
  );
}

export default MediaTabsHeader;
