import moment from "moment";
import "moment/locale/ru";
import { StrapiMedia } from "./apis/news";

const { REACT_APP_API_ENDPOINT } = process.env;

export const buildSrcSet = (media: StrapiMedia) => {
  const result = [];

  const { formats } = media;
  if (formats.thumbnail)
    result.push(
      `${REACT_APP_API_ENDPOINT}${formats?.thumbnail.url.substring(1)} 245w`
    );
  if (formats.small)
    result.push(
      `${REACT_APP_API_ENDPOINT}${formats?.small.url.substring(1)} 500w`
    );
  if (formats.medium)
    result.push(
      `${REACT_APP_API_ENDPOINT}${formats?.medium.url.substring(1)} 750w`
    );
  if (formats.large)
    result.push(
      `${REACT_APP_API_ENDPOINT}${formats?.large.url.substring(1)} 1000w`
    );

  result.push(
    `${REACT_APP_API_ENDPOINT}${media.url.substring(1)} ${media.width}w`
  );

  return result.join(",");
};

export function buildComplexClassName(
  ...classes: (string | false | undefined)[]
): string {
  return classes.reduce((className, current) => {
    if (current) return `${className} ${current}`;

    return className;
  }, "") as string;
}

export const DDToDMS = (d: number) => ({
  deg: 0 | (d < 0 ? (d = -d) : d),
  min: 0 | (((d += 1e-9) % 1) * 60),
  sec: (0 | (((d * 60) % 1) * 6000)) / 100,
});

export const formatDate = (d: Date): string => {
  return moment(new Date(d)).locale("ru").format("DD MMMM YYYY");
};

export const compactNumber = (n: number) => {
  if (n > 1_000_000_000_000) return "N/A";
  if (n >= 1_000_000_000)
    return `${Math.round((n / 1_000_000_000) * 10) / 10}B`;
  if (n >= 1_000_000) return `${Math.round((n / 1_000_000) * 10) / 10}M`;
  if (n >= 1_000) return `${Math.round((n / 1_000) * 10) / 10}K`;
  return `${n}`;
};

export function debounce<F extends (...params: any[]) => void>(
  fn: F,
  delay: number
) {
  let timeoutID: number | null = null;
  return function (this: any, ...args: any[]) {
    if (timeoutID !== null) clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}
