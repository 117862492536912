import { ReactNode } from "react";
import { buildComplexClassName } from "../../utils";
import css from "./PageTitle.module.css";

interface IPageTitleProps {
  children: ReactNode;
  uppercase?: boolean;
  className?: string;
}

function PageTitle({
  children,
  uppercase = false,
  className,
}: IPageTitleProps) {
  return (
    <h1
      className={buildComplexClassName(
        css.title,
        uppercase && css.uppercase,
        className
      )}
    >
      {children}
    </h1>
  );
}

export default PageTitle;
