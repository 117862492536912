import { useState } from "react";
import Pagination from "../../molecules/Pagination";
import css from "./DonationsTable.module.css";
import { ReactComponent as EmptyIcon } from "../../assets/icons/empty.svg";
import Spinner from "../../atoms/Spinner";
import { useGetDonationsQuery } from "../../apis/donations";

const formatCurrency = new Intl.NumberFormat("ru", {
  style: "currency",
  currency: "RUB",
});

const loading = (
  <div className={css.placeholder}>
    <div>
      <Spinner />
    </div>
  </div>
);

const empty = (
  <div className={css.placeholder}>
    <div className={css.empty}>
      <EmptyIcon />
      <span>Пусто</span>
    </div>
  </div>
);

const error = (
  <div className={css.placeholder}>
    <div className={css.empty}>
      <EmptyIcon />
      <span>Ошибка загрузки</span>
    </div>
  </div>
);

const ITEMS_ON_PAGE = 10;
function DonationsTable() {
  const [currentPage, setCurrentPage] = useState(0);

  const {
    isLoading,
    isError,
    data: rawData,
  } = useGetDonationsQuery({
    page: currentPage + 1,
    perPage: ITEMS_ON_PAGE,
  });

  const data = !rawData
    ? []
    : rawData.data.map((e) => ({
        slug: e.attributes.Slug,
        description: e.attributes.Description,
        value: parseFloat(e.attributes.Value),
      }));

  return (
    <div>
      {isLoading ? (
        loading
      ) : !data.length ? (
        empty
      ) : isError ? (
        error
      ) : (
        <ul className={css.list}>
          {data.map(({ slug, description, value }) => {
            return (
              <li className={css.listItem} title={String(value)} key={slug}>
                <p className={css.listItemLabel}>{description}</p>
                <p className={css.listItemValue}>
                  {formatCurrency.format(value)}
                </p>
              </li>
            );
          })}
        </ul>
      )}

      <Pagination
        current={currentPage}
        count={rawData?.meta.pagination.pageCount || 1}
        disabled={isLoading || isError}
        onSelect={(el) => setCurrentPage(el)}
      />
    </div>
  );
}

export default DonationsTable;
