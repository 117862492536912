import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import css from "./Brand.module.css";
import { FC } from "react";
import { buildComplexClassName } from "../../utils";

const Brand: FC<{ className?: string; noLink?: boolean }> = ({
  className,
  noLink = false,
}) => {
  if (noLink)
    return (
      <span className={buildComplexClassName(className)}>
        <img
          className={css.logo}
          src={logo}
          alt="АНО 'ПОКОЛЕНИЕ УВЕРЕННОГО БУДУЩЕГО. Логотип'"
        />
      </span>
    );

  return (
    <Link
      className={buildComplexClassName(className)}
      to="/"
      title="На главную"
    >
      <img
        className={css.logo}
        src={logo}
        alt="АНО 'ПОКОЛЕНИЕ УВЕРЕННОГО БУДУЩЕГО. Логотип'"
      />
    </Link>
  );
};

export default Brand;
