import { useEffect, useState } from "react";
import { FoundMediumList, useLazyGetMediumListQuery } from "../../apis/medium";
import Button from "../../atoms/Button";
import PageTitle from "../../atoms/PageTitle";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import { buildSrcSet } from "../../utils";
import css from "./MediumList.module.css";

const { REACT_APP_API_ENDPOINT } = process.env;
const PAGINATION_STEP = 5;

function MediumList() {
  const [fetchStories] = useLazyGetMediumListQuery();
  const [start, setStart] = useState(0);
  const [mediumList, setMediumList] = useState<FoundMediumList[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchStories({ start: start, limit: PAGINATION_STEP })
      .then(({ data: response }) => {
        if (!response) return;
        const { data, meta } = response || {};

        const { total } = meta.pagination;
        setIsLastPage(total <= start + PAGINATION_STEP);
        setMediumList((prevNews) => {
          if (start === 0) return data;
          return [...prevNews, ...data];
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchStories, start]);

  return (
    <NewDesignBasicPage>
      <div className={css.wrapper}>
        <PageTitle className={css.title}>Публикации в СМИ</PageTitle>
        <div className={css.list}>
          {mediumList.length > 0 ? (
            mediumList.map((entry) => {
              const story = entry.attributes;
              const {
                title,
                thumbnail: thumbnailResult,
                description,
                url,
              } = story;

              const thumbnail = thumbnailResult?.data.attributes;

              return (
                <a
                  key={entry.id}
                  target="_blank"
                  rel="noreferrer"
                  href={url}
                  className={css.story}
                >
                  <div className={css.thumbnail}>
                    <img
                      src={`${REACT_APP_API_ENDPOINT}${thumbnail.url.substring(
                        1
                      )}`}
                      alt={thumbnail?.caption || "Нет изображения"}
                      srcSet={thumbnail ? buildSrcSet(thumbnail) : undefined}
                    />
                  </div>
                  <div className={css.content}>
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </div>
                </a>
              );
            })
          ) : (
            <p>Публикаций пока нет</p>
          )}
          {!isLastPage && (
            <Button
              disabled={isLoading}
              className={css.button}
              type="button"
              onClick={() => setStart((p) => p + PAGINATION_STEP)}
            >
              Загрузить еще
            </Button>
          )}
        </div>
      </div>
    </NewDesignBasicPage>
  );
}

export default MediumList;
