import HandyText from "../../atoms/HandyText";
import PageTitle from "../../atoms/PageTitle";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import css from "./UserAgreement.module.css";

function UserAgreement() {
  return (
    <NewDesignBasicPage>
      <PageTitle className={css.title}>
        Согласие на обработку персональных данных
      </PageTitle>
      <div className={css.wrapper}>
        <div className={css.inner}>
          <HandyText>
            Регистрируясь на официальном сайте АНО «Поколение уверенного
            будущего» в информационно-телекоммуникационной сети «Интернет» по
            адресу: https://geroyregionov.ru (далее — официальный сайт), даю
            согласие на обработку моих персональных данных Автономной
            Некоммерческой организации по Развитию Образовательной, Культурной и
            Исследовательской Деятельности "Поколение Уверенного Будущего" (ОГРН
            1227700273481, адрес: 127083, г. Москва, ул 8 Марта, 14 / стр 1,
            эт/пом 1/VI оф 68) (далее - АНО «Поколение уверенного будущего»)
            (далее — Оператор).
          </HandyText>
          <HandyText>Целью обработки персональных данных является:</HandyText>
          <ul>
            <HandyText as="li">
              осуществление деятельности, связанной с подготовкой и проведением
              Конкурсного отбора организаций для осуществления деятельности по
              созданию и размещению в информационно-телекоммуникационной сети
              «Интернет» контента, направленного на укрепление гражданской
              идентичности и духовно-нравственных ценностей среди молодежи
              (далее – Конкурсный отбор) в соответствии с положениями АНО
              «Поколение уверенного будущего» (включая обеспечение Пользователю
              возможности заполнения и (или) подачи Заявок на участие в
              Конкурсном отборе, а также оперативного получения информации о
              рассмотрении таких Заявок; другой информации, связанной с
              подготовкой и проведением Конкурсного отбора, в том числе
              реализацией проектов — победителей Конкурсного отбора);
            </HandyText>
            <HandyText as="li">
              информирование Пользователя о деятельности АНО «Поколение
              уверенного будущего» посредством отправки электронных писем;
            </HandyText>
            <HandyText as="li">
              предоставление доступа Пользователю к сервисам, информации и/или
              материалам, содержащимся на официальном сайте АНО «Поколение
              уверенного будущего».
            </HandyText>
          </ul>
          <HandyText>
            Перечень персональных данных, которые могут обрабатываться в
            соответствии с настоящим согласием:
          </HandyText>
          <ul>
            <HandyText as="li">Фамилия</HandyText>
            <HandyText as="li">Имя</HandyText>
            <HandyText as="li">Отчество</HandyText>
            <HandyText as="li">Номер(а) контактного(ых) телефона(ов)</HandyText>
            <HandyText as="li">
              Адрес электронной почты и иные контактные данные
            </HandyText>
          </ul>
          <HandyText>
            Настоящее согласие предоставляется на автоматизированную, а также
            без использования средств автоматизации обработку моих персональных
            данных, а именно на обработку, включая сбор, систематизацию,
            накопление, хранение, уточнение (обновление, изменение),
            использование, распространение (в том числе передачу),
            обезличивание, блокировку, уничтожение всех персональных данных,
            необходимых для указанных в настоящем согласии целей обработки.
            Настоящее согласие выдано бессрочно. Отзыв настоящего согласия
            производится в письменной форме путем направления соответствующего
            уведомления по адресу АНО «Поколение уверенного будущего». Мне
            известно, что в случае отзыва настоящего согласия достижение целей
            обработки персональных данных, указанных в настоящем согласии,
            станет невозможным.
          </HandyText>
        </div>
      </div>
    </NewDesignBasicPage>
  );
}

export default UserAgreement;
