import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { FindResult } from "../strapi-types";

export type PageConstant = {
  UniqueIdentificator: string;
  Value: string;
  createdAt: string;
  updatedAt: string;
};

export const DONATION_UID = "money-donated";
export const GIFTS_UID = "gifts-received";

export type DonationItem = {
  Description: string;
  MetaData: any;
  Slug: string;
  Value: string;
  createdAt: string;
  updatedAt: string;
};

export const donationsAPI = createApi({
  reducerPath: "donationsAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}api`,
  }),
  endpoints: (builder) => ({
    getStatistics: builder.query<
      {
        data: FindResult<PageConstant>[];
      },
      void
    >({
      query: () =>
        `/page-constants?filters[UniqueIdentificator][$in][0]=${DONATION_UID}&filters[UniqueIdentificator][$in][1]=${GIFTS_UID}`,
    }),
    getDonations: builder.query<
      {
        data: FindResult<DonationItem>[];
        meta: {
          pagination: {
            page: number;
            pageCount: number;
          };
        };
      },
      { page: number; perPage: number }
    >({
      query: ({ page, perPage }) =>
        `/donations?pagination[page]=${page}&pagination[pageSize]=${perPage}`,
    }),
  }),
});

export const { useGetStatisticsQuery, useGetDonationsQuery } = donationsAPI;
