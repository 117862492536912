import { ReactNode } from "react";
import Footer from "../../organisms/Footer";
import Header from "../../organisms/Header";
import css from "./NewDesignBasicPage.module.css";

interface INewDesignBasicPageProps {
  children: ReactNode;
  /** Class name for .content wrapper */
  className?: string;
}

function NewDesignBasicPage({ children }: INewDesignBasicPageProps) {
  return (
    <div className={css.wrapper}>
      <Header />
      <div className={css.innerWrapper}>
        <div className={css.content}>{children}</div>
        <Footer />
      </div>
    </div>
  );
}

export default NewDesignBasicPage;
