import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import { useAppDispatch } from "../../hooks/redux";
import { loginUser } from "../../slices/auth";
import FormPage from "../../templates/FormPage";
import css from "./LoginPage.module.css";

function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <FormPage title="Вход">
      <form
        className={css.form}
        onSubmit={async (event) => {
          try {
            event.preventDefault();
            const formData = new FormData(event.target as HTMLFormElement);
            const identifier = formData.get("identifier");
            const password = formData.get("password");

            if (!identifier || typeof identifier !== "string")
              throw new Error("Идентификатор имеет неверный тип");
            if (!password || typeof password !== "string")
              throw new Error("Пароль имеет неверный тип");

            const { payload } = await dispatch(
              loginUser({ identifier, password })
            );
            if ("name" in (payload as any)) {
              if (
                (payload as any).message ===
                "Your account email is not confirmed"
              )
                return toast((t) => (
                  <div className={css.toast}>
                    <div style={{ marginBottom: 5 }}>
                      Все введено верно. Но вы не подтвердили свой аккаунт
                      ранее. Поищите сообщение на почте и активируйте код.
                    </div>
                    <Button
                      small
                      onClick={() => {
                        navigate("/email-confirmation");
                        toast.dismiss(t.id);
                      }}
                    >
                      Подтвердить
                    </Button>
                  </div>
                ));
              switch ((payload as any).name) {
                case "ValidationError":
                  return toast.error("Неверный логин или пароль");
                default:
                  return toast.error("Неизвестная ошибка");
              }
            } else {
              navigate("/");
            }
          } catch (error) {
            toast.error((error as any)?.message || "Неизвестная ошибка");
          }
        }}
      >
        <Input required name="identifier" type="text" placeholder="Email" />
        <Input required name="password" type="password" placeholder="Пароль" />
        <Button type="submit">Войти</Button>
        <div className={css.links}>
          <Link to="/forgot-password">Забыли пароль?</Link>
          <Link to="/sign-up">Зарегистрироваться</Link>
        </div>
      </form>
    </FormPage>
  );
}

export default LoginPage;
