import { useEffect, useState } from "react";
import { ReducedNewsObject, useLazyGetManyNewsQuery } from "../../apis/news";
import Button from "../../atoms/Button";
import NewsCard from "../../molecules/NewsCard";
import { CollectionItem } from "../../strapi-types";
import css from "./News.module.css";

interface INewsProps {
  paginationStep: number;
}

function News({ paginationStep }: INewsProps) {
  const [start, setStart] = useState(0);
  const [news, setNews] = useState<CollectionItem<ReducedNewsObject>[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchNews] = useLazyGetManyNewsQuery();

  useEffect(() => {
    setIsLoading(true);
    fetchNews({ start: start, limit: paginationStep })
      .then(({ data: response }) => {
        if (!response) return;
        const { data, meta } = response || {};

        const { total } = meta.pagination;
        setIsLastPage(total <= start + paginationStep);
        setNews((prevNews) => {
          if (start === 0) return data;
          return [...prevNews, ...data];
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchNews, paginationStep, start]);

  if (!isLoading && news.length === 0) return <div>Новостей пока нет</div>;

  return (
    <div className={css.wrapper}>
      {isLoading && <div>Загрузка</div>}
      {news.map((newsEntity) => (
        <NewsCard
          className={css.card}
          key={newsEntity.id}
          {...newsEntity.attributes}
        />
      ))}
      {!isLastPage && (
        <Button
          type="button"
          className={css.button}
          onClick={() => {
            setStart(news.length);
          }}
          disabled={isLoading}
        >
          Загрузить еще
        </Button>
      )}
    </div>
  );
}

export default News;
