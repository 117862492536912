import { buildComplexClassName } from "../../utils";
import css from "./PayBanner.module.css";
import { ReactComponent as SberPayQR } from "./sber-pay-qr.svg";
import { ReactComponent as SberPayButton } from "./sber-pay-button.svg";
// import bells from "./bells.svg";

const SBER_PAY_LINK = "https://platiqr.ru/?uuid=1000379993";

function PayBanner() {
  return (
    <div className={css.wrapper}>
      {/* <img className={css.bells} src={bells} alt="Колокольчики" /> */}
      <h3 className={css.title}>Внесите добровольное пожертвование</h3>
      <p className={css.callToAction}>
        Для этого вы можете отсканировать QR код. Он работает с приложением
        Сбербанка. После обработки ваш платеж появится в списке пожертвований.
      </p>
      <p className={buildComplexClassName(css.callToAction, css.mobile)}>
        Для этого просто нажмите кнопку. Откроется приложение Сбербанка. После
        обработки ваш платеж появится в списке пожертвований.
      </p>
      <a
        className={css.button}
        href={SBER_PAY_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <SberPayButton />
      </a>
      <SberPayQR className={css.qr} />
    </div>
  );
}

export default PayBanner;
