import { Children, ReactNode } from "react";
import css from "./ItemsGrid.module.css";

interface IItemsGridProps<D> {
  children: (entry: D) => ReactNode;
  items: D[];
}

function ItemsGrid<D>({ children, items }: IItemsGridProps<D>) {
  return (
    <div className={css.list}>
      {Children.toArray(
        items.map((item) => <div className={css.item}>{children(item)}</div>)
      )}
    </div>
  );
}

export default ItemsGrid;
