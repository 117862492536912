import PageTitle from "../../atoms/PageTitle";
import News from "../../organisms/News";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import css from "./NewsListPage.module.css";

const PAGINATION_STEP = 5;

function NewsListPage() {
  return (
    <NewDesignBasicPage>
      <main className={css.main}>
        <div className={css.content}>
          <PageTitle className={css.title}>Новости</PageTitle>
          <News paginationStep={PAGINATION_STEP} />
        </div>
      </main>
    </NewDesignBasicPage>
  );
}

export default NewsListPage;
