import { FC, FormEvent, useState } from "react";
import css from "./TinkoffPayment.module.css";
import toast from "react-hot-toast";
import { nanoid } from "@reduxjs/toolkit";

const { REACT_APP_TINKOFF_TERMINAL_ID } = process.env;

const TinkoffPayment: FC = () => {
  const [amount, setAmount] = useState("100.00");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) return;

    try {
      const amountToSend = Math.ceil(Number(amount) * 100);
      if (!Number.isSafeInteger(amountToSend))
        throw new Error("Неверно указана сумма");

      setIsLoading(true);
      const response = await fetch("https://securepay.tinkoff.ru/v2/Init", {
        method: "POST",
        body: JSON.stringify({
          TerminalKey: REACT_APP_TINKOFF_TERMINAL_ID,
          Amount: amountToSend,
          OrderId: nanoid(),
          Description: "Благотворительный перевод",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      if (!json.Success) {
        throw new Error(`Tinkoff: ${json.Details || json.Message}`);
      }

      if (!json.PaymentURL) throw new Error();

      const { PaymentURL } = json;
      window.open(PaymentURL, "_blank")?.focus();
    } catch (error) {
      toast.error((error as any)?.message || "Неизвестная ошибка");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={css.wrapper}>
      <p className={css.title}>Вы тоже можете сделать вклад</p>
      <form className={css.form} onSubmit={onSubmit}>
        <input
          className={css.amount}
          type="text"
          inputMode="numeric"
          name="amount"
          pattern="[0-9]*\.[0-9]{2}"
          placeholder="Введите сумму"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <span className={css.currency}>₽</span>
        <button disabled={isLoading} className={css.send} type="submit">
          Отправить
        </button>
      </form>
    </div>
  );
};

export default TinkoffPayment;
