import { ReactHTML } from "react";
import { ReactNode } from "react";
import { buildComplexClassName } from "../../utils";
import css from "./HandyText.module.css";

interface IHandyTextProps {
  children: ReactNode;
  as?: keyof ReactHTML;
  className?: string;
}

function HandyText({
  children,
  as: Wrapper = "p",
  className,
}: IHandyTextProps) {
  return (
    <Wrapper className={buildComplexClassName(css.wrapper, className)}>
      {children}
    </Wrapper>
  );
}

export default HandyText;
