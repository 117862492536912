import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store";
import AppRouter from "./AppRouter";
import reportWebVitals from "./reportWebVitals";
import "./fonts.css";
import "./index.css";
import { Toaster } from "react-hot-toast";
import AppRouterReduced from "./AppRouterReduced";

const container = document.getElementById("root")!;
const root = createRoot(container);

if (process.env.REACT_APP_BUILD_TARGET === "reduced") {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Toaster position="top-right" />
          <AppRouterReduced />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Toaster position="top-right" />
          <AppRouter />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
