import { useEffect, useState } from "react";
import Button from "../../atoms/Button";
import { useAppSelector } from "../../hooks/redux";
import StoryCard from "../../molecules/StoryCard";
import { MyStoriesItem } from "../../strapi-types";
import css from "./ProfileStories.module.css";
// import { ReactComponent as PenIcon } from "css.gg/icons/svg/pen.svg";
import StoriesTabsHeader from "../../molecules/StoriesTabsHeader";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import PageTitle from "../../atoms/PageTitle";
import toast from "react-hot-toast";

const { REACT_APP_API_ENDPOINT } = process.env;
const PAGINATION_STEP = 2;

function RemoveButton({ id }: { id: number }) {
  const [isLoading, setIsLoading] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const jwt = useAppSelector((s) => s.auth.jwt);

  if (areYouSure)
    return (
      <div className={css.areYouSure}>
        <span className={css.label}>Вы уверены?</span>
        <Button
          disabled={isLoading}
          className={css.areYouSureButton}
          onClick={async () => {
            if (!jwt) return;
            setIsLoading(true);

            try {
              const response = await fetch(
                `${REACT_APP_API_ENDPOINT}api/my-story/${id}`,
                {
                  method: "DELETE",
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                  },
                }
              );
              const json = await response.json();
              if ("error" in json) throw new Error(json.error);

              setAreYouSure(false);
              window.location.reload();
            } catch (error) {
              toast.error((error as Error).message);
            } finally {
              setIsLoading(false);
            }
          }}
          secondary
        >
          Удалить
        </Button>
        <Button
          className={css.areYouSureButton}
          onClick={() => setAreYouSure(false)}
        >
          Отмена
        </Button>
      </div>
    );

  return (
    <Button
      onClick={() => setAreYouSure(true)}
      secondary
      className={css.remove}
    >
      Удалить
    </Button>
  );
}

// function EditButton() {
//   return (
//     <button className={css.iconButton} title="Редактировать">
//       <PenIcon />
//     </button>
//   );
// }

function ProfileStories() {
  const jwt = useAppSelector((s) => s.auth.jwt);
  const [start, setStart] = useState(0);
  const [stories, setStories] = useState<MyStoriesItem[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!jwt) return;

    setIsLoading(true);
    fetch(
      `${REACT_APP_API_ENDPOINT}api/my-stories?offset=${start}&limit=${PAGINATION_STEP}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    )
      .then(async (response) => {
        const json = (await response.json()) as
          | { error: string }
          | { result: MyStoriesItem[]; isLast: boolean };

        if ("error" in json) throw new Error(json.error);

        setIsLastPage(json.isLast);
        setStories((prevStories) => {
          if (start === 0) return json.result;
          return [...prevStories, ...json.result];
        });
      })
      .catch((error: Error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [jwt, start]);

  return (
    <NewDesignBasicPage>
      <StoriesTabsHeader className={css.header} />
      <div className={css.wrapper}>
        <PageTitle className={css.title}>Мои истории</PageTitle>
        <div className={css.inner}>
          {stories.length > 0 ? (
            stories.map((story) => (
              <StoryCard
                className={css.story}
                key={story.id}
                title={
                  <>
                    {story.title}
                    {!story.isPublished && (
                      <span className={css.gray}> (Обработка)</span>
                    )}
                  </>
                }
                thumbnail={story.thumbnail}
                createdAt={new Date(story.createdAt)}
              >
                <RemoveButton id={story.id} />
              </StoryCard>
            ))
          ) : (
            <p>Список пуст</p>
          )}
          {!isLastPage && (
            <Button
              className={css.button}
              disabled={isLoading}
              type="button"
              onClick={() => setStart((p) => p + PAGINATION_STEP)}
            >
              Загрузить еще
            </Button>
          )}
        </div>
      </div>
    </NewDesignBasicPage>
  );
}

export default ProfileStories;
