import HandyText from "../../atoms/HandyText";
import css from "./AboutPage.module.css";
import { ReactComponent as DocumentIcon } from "css.gg/icons/svg/file-document.svg";
import PageTitle from "../../atoms/PageTitle";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";

function AboutPage() {
  return (
    <NewDesignBasicPage>
      <div className={css.wrapper}>
        <div className={css.inner}>
          <PageTitle>О нас</PageTitle>
          <HandyText>
            В ходе реализации проектов ребятам предоставлена возможность
            социальных и профессиональных проб в разных отраслях экономики, на
            ключевых предприятиях региона, а также стажировки на производстве.
          </HandyText>
          <HandyText>В рамках проекта тренерская команда помогает в:</HandyText>
          <HandyText as="ul">
            <li>
              Расширении инструментов и механизмов выявления
              высокомотивированных и социально-активных детей и молодежи.
            </li>
            <li>
              Содействии субъектам Российской Федерации в формировании
              инфраструктуры поддержки деловой и социальной активности детей и
              молодёжи.
            </li>
            <li>
              Создании современной и безопасной цифровой образовательной среды,
              обеспечивающей интеграцию основного, дополнительного и
              неформального образования детей и молодежи.
            </li>
            <li>
              Развитии форм профессиональной навигации детей и молодежи,
              моделирование их профессиональной деятельности.
            </li>
            <li>
              Разработке механизмов мотивации детей и молодёжи к участию в
              социально-экономическом развитии своего региона.
            </li>
            <li>Внедрении новых форм наставничества.</li>
          </HandyText>
          <HandyText>
            Мероприятее: "ШКОЛА ПРОЕКТНЫХ ТЕХНОЛОГИЙ" ПОКОЛЕНИЕ УВЕРЕННОГО
            БУДУЩЕГО" (финансировалась за счет Московское городское региональное
            отделение Партии "ЕДИНАЯ РОССИЯ")
          </HandyText>
          <HandyText>
            "Проектные технологии - это путь от идеи до презентации проекта и
            реализации этих идей на практике. Это не просто очередной тренинг, а
            реальная возможность воплотить в жизнь самые смелые инициативы.
          </HandyText>
          <HandyText>
            В 2022 году в период с 03 по 07 май 2022 года РОО Ратник проводило
            совместно с Общероссийской общественной организацией «Российская
            Ассоциация Героев» мероприятий в рамках проекта «Вахта Героев
            Отечества». Цель мероприятия: возрождение и сохранение
            духовно-нравственных традиций, приобщение воспитанников к
            историческому прошлому Родины, воспитание патриотизма, активной
            гражданской позиции, формирование личности, способной противостоять
            современным вызовам и угрозам – наиболее важная задача на
            современном этапе
          </HandyText>
          <h2>Документы</h2>
          <HandyText>
            Здесь вы можете скачать и ознакомиться с важными для организации
            документами
          </HandyText>
          <div className={css.documents}>
            <a
              className={css.documentsItem}
              href="/public-documents/Положение-о-конкурсе-акции-Новый-год-в-новые-регионы.pdf"
              download
            >
              <DocumentIcon className={css.icon} />
              <p>
                Положение о проведении всероссийского конкурса-акции новогодних
                открыток "Новый год - в новые регионы"
              </p>
            </a>
            <a
              className={css.documentsItem}
              href="/public-documents/Поколение-уверенного-будущего-Свидетельство-о-постановке-на-учет.pdf"
              download
            >
              <DocumentIcon className={css.icon} />
              <p>
                Свидетельство о постановке на учет в налоговом органе АНО
                "Поколение уверенного будущего"
              </p>
            </a>
            <a
              className={css.documentsItem}
              href="/public-documents/Поколение-уверенного-будущего-Свидетельство-о-регистрации-некоммерческой-организации.pdf"
              download
            >
              <DocumentIcon className={css.icon} />
              <p>
                Свидетельство о государственной регистрации АНО "Поколение
                уверенного будущего"
              </p>
            </a>
            <a
              className={css.documentsItem}
              href="/public-documents/Ратник-Свидетельство-о-постановке-на-учет.pdf"
              download
            >
              <DocumentIcon className={css.icon} />
              <p>
                Свидетельство о постановке на учет в налоговом органе
                организации "Ратник"
              </p>
            </a>
            <a
              className={css.documentsItem}
              href="/public-documents/Ратник-Свидетельство-о-регистрации-некоммерческой-организации.pdf"
              download
            >
              <DocumentIcon className={css.icon} />
              <p>
                Свидетельство о государственной регистрации организации "Ратник"
              </p>
            </a>
          </div>
        </div>
      </div>
    </NewDesignBasicPage>
  );
}

export default AboutPage;
