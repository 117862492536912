import HandyText from "../../atoms/HandyText";
import PageTitle from "../../atoms/PageTitle";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import css from "./PrivacyPolicy.module.css";

function PrivacyPolicy() {
  return (
    <NewDesignBasicPage>
      <PageTitle className={css.title}>
        Политика конфиденциальности в области обработки и обеспечения
        безопасности персональных данных, обрабатываемых на сайтах АНО
        «Поколение Уверенного Будущего»
      </PageTitle>
      <div className={css.wrapper}>
        <div className={css.inner}>
          <h2>1. Общие положения</h2>
          <HandyText>
            1.1. Настоящая Политика конфиденциальности в области обработки и
            обеспечения безопасности персональных данных, обрабатываемых на
            сайтах АНО «Поколение Уверенного Будущего» (далее – Политика
            конфиденциальности) разработана в соответствии с требованиями
            Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» и
            определяет порядок обработки персональных данных и меры, принимаемые
            Автономной некоммерческой организацией «Поколение Уверенного
            Будущего» (далее – АНО «Поколение Уверенного Будущего», Оператор) по
            обеспечению безопасности персональных данных пользователей сайтов
            АНО «Поколение Уверенного Будущего», размещенных в
            информационно-телекоммуникационной сети Интернет по адресам:
            https://geroyregionov.ru, https://futureinyou.ru,
            https://героирегионов.рф (далее – Сайт Оператора).
          </HandyText>
          <HandyText>
            1.2. Оператор не контролирует и не несет ответственность за сайты
            третьих лиц, на которые Пользователь может перейти по ссылкам,
            доступным на Сайте Оператора.{" "}
          </HandyText>
          <HandyText>
            1.3. Использование Сайта Оператора Пользователем означает согласие с
            настоящей Политикой конфиденциальности.
          </HandyText>
          <HandyText>
            1.4. В случае несогласия с условиями Политики конфиденциальности,
            Пользователь должен прекратить использование Сайта Оператора.
          </HandyText>
          <h2>2. Термины и определения</h2>
          <HandyText>
            2.1. В настоящей Политике конфиденциальности используются следующие
            термины:
          </HandyText>
          <HandyText>
            2.1.1. Автоматизированная обработка персональных данных – обработка
            персональных данных с помощью средств вычислительной техники;{" "}
          </HandyText>
          <HandyText>
            2.1.2. Блокирование персональных данных – временное прекращение
            обработки персональных данных (за исключением случаев, если
            обработка необходима для уточнения персональных данных);
          </HandyText>
          <HandyText>
            2.1.3. Конфиденциальность персональных данных — обязательный для
            соблюдения оператором или иным получившим доступ к персональным
            данным лицом режим персональных данных, не допускающий их
            распространения без согласия субъекта персональных данных или
            наличия иного законного основания;
          </HandyText>
          <HandyText>
            2.1.4. Обезличивание персональных данных — действия, в результате
            которых невозможно определить без использования дополнительной
            информации принадлежность персональных данных конкретному
            Пользователю или иному субъекту персональных данных;
          </HandyText>
          <HandyText>
            2.1.5. Обработка персональных данных – любое действие (операция) или
            совокупность действий (операций), совершаемых с использованием
            средств автоматизации или без использования таких средств с
            персональными данными, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных
            данных;
          </HandyText>
          <HandyText>
            2.1.6. Оператор – государственный орган, муниципальный орган,
            юридическое или физическое лицо, самостоятельно или совместно с
            другими лицами организующие и (или) осуществляющие обработку
            персональных данных, а также определяющие цели обработки
            персональных данных, состав персональных данных, подлежащих
            обработке, действия (операции), совершаемые с персональными данными;
          </HandyText>
          <HandyText>
            2.1.7. Персональные данные – любая информация, относящаяся к прямо
            или косвенно определенному, или определяемому пользователю сайта
            Оператора (https://geroyregionov.ru,
            https://futureinyou.ru,https://героирегионов.рф);
          </HandyText>
          <HandyText>
            2.1.8. Пользователь – любой посетитель веб-сайта АНО «Поколение
            Уверенного Будущего» в информационно-телекоммуникационной сети
            «Интернет» (https://geroyregionov.ru,
            https://futureinyou.ru,https://героирегионов.рф);
          </HandyText>
          <HandyText>
            2.1.9. Предоставление персональных данных – действия, направленные
            на раскрытие персональных данных определенному лицу или
            определенному кругу лиц;
          </HandyText>
          <HandyText>
            2.1.10. Распространение персональных данных – любые действия,
            направленные на раскрытие персональных данных неопределенному кругу
            лиц (передача персональных данных) или на ознакомление с
            персональными данными неограниченного круга лиц, в том числе
            обнародование персональных данных в средствах массовой информации,
            размещение в информационно-телекоммуникационных сетях или
            предоставление доступа к персональным данным каким-либо иным
            способом;
          </HandyText>
          <HandyText>
            2.1.11. Сайт Оператора – совокупность графических и информационных
            материалов, а также программ для ЭВМ и баз данных, обеспечивающих их
            доступность в сети Интернет;
          </HandyText>
          <HandyText>
            2.1.12. Уничтожение персональных данных – любые действия, в
            результате которых персональные данные уничтожаются безвозвратно с
            невозможностью дальнейшего восстановления содержания персональных
            данных в информационной системе персональных данных и (или) в
            результате которых уничтожаются материальные носители персональных
            данных.
          </HandyText>
          <h2>3. Цели обработки персональных данных</h2>
          <HandyText>
            3.1. Основная цель обработки персональных данных Пользователя –
            осуществление деятельности, связанной с подготовкой и проведением
            конкурсных отборов организаций для осуществления деятельности по
            созданию и размещению в информационно-телекоммуникационной сети
            «Интернет» контента, направленного на укрепление гражданской
            идентичности и духовно-нравственных ценностей среди молодежи (далее
            – Конкурсный отбор), предоставление доступа Пользователю к сервисам,
            информации и/или материалам, содержащимся на Сайте.
          </HandyText>
          <HandyText>
            3.2. Персональные данные предоставляются Пользователем
            самостоятельно, одним из следующих..способов:
          </HandyText>
          <HandyText as="ul">
            <li>
              заполнения специальной формы, размещенной на Сайте Оператора;
            </li>
            <li>отправляя свои данные Оператору;</li>
            <li>
              путем внесения в информационную систему, обеспечивающую процедуру
              подготовки и проведения Конкурсного отбора, доступ к которой
              осуществляется через Сайт Оператора.
            </li>
            <li>
              Заполняя соответствующие формы и/или отправляя свои персональные
              данные Оператору, Пользователь выражает свое согласие с настоящей
              Политикой конфиденциальности. Оператор обрабатывает обезличенные
              данные о Пользователе в случае, если это разрешено в настройках
              браузера Пользователя (включено сохранение файлов «cookie» и
              использование технологии JavaScript).
            </li>
          </HandyText>
          <HandyText>
            3.3 Оператор может обрабатывать следующие персональные данные
            Пользователя:
          </HandyText>
          <HandyText>3.3.1. фамилия;</HandyText>
          <HandyText>3.3.2. имя;</HandyText>
          <HandyText>3.3.3. отчество;</HandyText>
          <HandyText>3.3.4. пол;</HandyText>
          <HandyText>3.3.5. дата рождения;</HandyText>
          <HandyText>
            3.3.6. сведения о текущей и предыдущей деятельности;
          </HandyText>
          <HandyText>3.3.7. номер(а) контактного(ых) телефона(ов);</HandyText>
          <HandyText>
            3.3.8. адрес электронной почты и иные контактные данные;
          </HandyText>
          <HandyText>3.3.9. паспортные данные;</HandyText>
          <HandyText>3.3.10. адрес места жительства;</HandyText>
          <HandyText>3.3.11. сведения об образовании;</HandyText>
          <HandyText>
            3.3.12. персональные данные, в том числе в составе медиафайлов,
            вносимые в информационную систему, обеспечивающую процедуру
            подготовки и проведения Конкурсного отбора, доступ к которой
            осуществляется через Сайт Оператора.
          </HandyText>
          <HandyText>
            3.3.13. иные данные и информацию, которую пользователь передает
            Оператору через Сайт Оператора, или по электронной почте, или по
            другим каналам связи.
          </HandyText>
          <HandyText>
            3.4. Сайт Оператора защищает данные, которые автоматически
            передаются при посещении страниц Сайта:
          </HandyText>
          <HandyText as="ul">
            <li>IP-адрес;</li>
            <li>информация из cookies;</li>
            <li>информация о браузере;</li>
            <li>время доступа;</li>
            <li>реферер (адрес предыдущей страницы).</li>
          </HandyText>
          <HandyText>
            3.5. Отключение cookies может повлечь невозможность доступа к частям
            Сайта Оператора, требующим авторизации.
          </HandyText>
          <h2>
            4. Порядок сбора, хранения, способы и сроки обработки персональных
            данных
          </h2>
          <HandyText>
            4.1. Сроки обработки персональных данных определяются с учетом целей
            обработки персональных данных, указанных в настоящей Политике,
            требований законодательства и воли соответствующих субъектов
            персональных данных.
          </HandyText>
          <HandyText>
            4.2. Условием прекращения обработки персональных данных являются, в
            частности, достижение целей обработки персональных данных, отзыв
            согласия субъекта персональных данных на обработку его персональных
            данных, а также выявление неправомерной обработки персональных
            данных.
          </HandyText>
          <HandyText>
            4.3. Персональные данные Пользователя могут быть переданы
            уполномоченным органам государственной власти Российской Федерации
            только по основаниям и в порядке, установленным действующим
            законодательством Российской Федерации.
          </HandyText>
          <HandyText>
            4.4. Пользователь имеет право на получение у Оператора информации,
            касающейся обработки его персональных данных, если такое право не
            ограничено в соответствии с федеральными законами. Пользователь
            вправе требовать от Оператора уточнения его персональных данных, их
            блокирования или уничтожения в случае, если персональные данные
            являются неполными, устаревшими, неточными, незаконно полученными
            или не являются необходимыми для заявленной цели обработки, а также
            принимать предусмотренные законом меры по защите своих прав. Для
            этого достаточно уведомить по адресу местонахождения Оператора.
          </HandyText>
          <HandyText>
            4.5. Оператор принимает необходимые организационные и технические
            меры для защиты персональной информации Пользователя от
            неправомерного или случайного доступа, уничтожения, изменения,
            блокирования, копирования, распространения, а также от иных
            неправомерных действий третьих лиц.
          </HandyText>
          <HandyText>
            4.6. Пользователь может получить любые разъяснения по интересующим
            вопросам, касающимся обработки его персональных данных, обратившись
            к Оператору с помощью электронной почты.
          </HandyText>
          <HandyText>
            4.7. В данном документе будут отражены любые изменения Политики
            конфиденциальности. Политика действует бессрочно до замены ее новой
            версией.
          </HandyText>
          <HandyText>
            4.8. Все предложения или вопросы относительно настоящей Политики
            конфиденциальности следует сообщать на электронную почту
            idemvmeste@bk.ru.
          </HandyText>
          <HandyText>
            4.9. Действующая Политика конфиденциальности размещена в
            информационно-телекоммуникационной сети «Интернет» по адресу:
            https://geroyregionov.ru
          </HandyText>
        </div>
      </div>
    </NewDesignBasicPage>
  );
}

export default PrivacyPolicy;
