import { lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../atoms/Button";
import PageTitle from "../../atoms/PageTitle";
import Spinner from "../../atoms/Spinner";
import NewsPreview from "../../organisms/NewsPreview";
import PayBanner from "../../organisms/PayBanner";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import css from "./Index.module.css";
const MapSection = lazy(async () => ({
  default: (await import("../../organisms/Map")).MapSection,
}));

function Index() {
  const navigate = useNavigate();

  return (
    <NewDesignBasicPage>
      <PayBanner />
      <section className={css.header}>
        <div className={css.content}>
          <p className={css.label}>
            Автономная некоммерческая организация по развитию образовательной,
            культурной и исследовательской деятельности
          </p>
          <PageTitle uppercase className={css.title}>
            "Поколение уверенного будущего"
          </PageTitle>
          <p className={css.description}>
            Проект "Герои регионов" знакомит россиян с выдающимися личностями,
            которые проживают в их родных краях. Мы предлагаем вместе с вами
            найти достойных сограждан и рассказать о них на площадке проекта.
            Героями могут быть не только военные, волонтеры, но и учителя, врачи
            и все, кем по праву может гордиться регион. Давайте ценить друг
            друга и вдохновляться на примере лучших: не звезд из соцсетей, а
            реальных героев, своим трудом помогающих стране развиваться.
          </p>
        </div>
      </section>
      <Suspense fallback={<Spinner />}>
        <MapSection />
      </Suspense>
      <section className={css.news}>
        <div className={css.content}>
          <h2 className={css.title}>Новости</h2>
          <NewsPreview />
          <Button
            type="button"
            tabIndex={0}
            role="link"
            data-href="/news"
            className={css.button}
            onClick={() => navigate("/news")}
          >
            Все новости
          </Button>
        </div>
      </section>
    </NewDesignBasicPage>
  );
}

export default Index;
