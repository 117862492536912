import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../strapi-types";

const JWT_LOCAL_STORAGE_KEY = "strapi-app/jwt-token";

export const loginFromLocal = createAsyncThunk(
  "authSlice/loginFromLocal",
  async (_, api) => {
    const jwt = localStorage.getItem(JWT_LOCAL_STORAGE_KEY);
    if (!jwt) return;

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}api/users/me`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    const json = (await response.json()) as
      | {
          error: { message: string };
        }
      | User;

    if (!response.ok) return api.rejectWithValue("");

    return json;
  }
);

export const loginUser = createAsyncThunk(
  "authSlice/loginUser",
  async (props: { identifier: string; password: string }, api) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}api/auth/local`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(props),
      }
    );
    const json = (await response.json()) as
      | {
          error: { message: string };
        }
      | {
          jwt: string;
          user: User;
        };

    if (!("jwt" in json)) return api.rejectWithValue(json?.error);

    localStorage.setItem(JWT_LOCAL_STORAGE_KEY, json.jwt);
    return json;
  }
);

export const registerUser = createAsyncThunk(
  "authSlice/registerUser",
  async (
    props: {
      email: string;
      fullName: string;
      password: string;
    },
    api
  ) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}api/auth/local/register`,
      {
        body: JSON.stringify({ ...props, username: props.email }),
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    const json = (await response.json()) as
      | {
          error: { message: string };
        }
      | {
          user: User;
        };

    if (!("user" in json)) return api.rejectWithValue(json?.error);
    return json;
  }
);

const initialState: {
  isAuthProcess: boolean;
  user?: User;
  jwt?: string;
  error?: string;
} = {
  isAuthProcess: true,
  jwt: localStorage.getItem(JWT_LOCAL_STORAGE_KEY) || undefined,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem(JWT_LOCAL_STORAGE_KEY);
      return {
        isAuthProcess: false,
      };
    },
    manualLogin: (
      state,
      action: PayloadAction<{ jwt: string; user: User }>
    ) => {
      state.jwt = action.payload.jwt;
      state.user = action.payload.user;
      localStorage.setItem(JWT_LOCAL_STORAGE_KEY, action.payload.jwt);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.isAuthProcess = true;
    });

    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.isAuthProcess = false;
      state.jwt = payload.jwt;
      state.user = payload.user;
    });

    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.isAuthProcess = false;
      state.error = (payload as any)?.message || "Unknown error";
    });

    builder.addCase(registerUser.pending, (state) => {
      state.isAuthProcess = true;
    });

    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.isAuthProcess = false;
      state.error = undefined;
      state.user = payload.user;
    });

    builder.addCase(registerUser.rejected, (state, { payload }) => {
      state.isAuthProcess = false;
      state.error = (payload as any)?.message || "Unknown error";
    });

    builder.addCase(loginFromLocal.pending, (state) => {
      state.isAuthProcess = true;
    });

    builder.addCase(loginFromLocal.fulfilled, (state, { payload }) => {
      state.isAuthProcess = false;
      if (!payload) return;

      if ("error" in payload) return;

      state.user = payload;
    });

    builder.addCase(loginFromLocal.rejected, (state) => {
      state.isAuthProcess = false;
    });
  },
});

export default authSlice.reducer;
export const { logout, manualLogin } = authSlice.actions;
