import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetVideoQuery } from "../../apis/media";
import Spinner from "../../atoms/Spinner";
import VideoPlayer from "../../organisms/VideoPlayer";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import { compactNumber, formatDate } from "../../utils";
import { ReactComponent as EyeIcon } from "css.gg/icons/svg/eye.svg";
import css from "./Video.module.css";

const { REACT_APP_API_ENDPOINT } = process.env;

function Video() {
  const { videoId } = useParams();
  if (!videoId) throw new Error("Страница не соответствует пути");
  const { data: response, isLoading, isError } = useGetVideoQuery(videoId);

  useEffect(() => {
    if (!response) return;

    const timer = setTimeout(async () => {
      await fetch(
        `${REACT_APP_API_ENDPOINT}api/video-views/${response.data.id}`
      );
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [response]);

  if (isLoading)
    return (
      <NewDesignBasicPage>
        <Spinner />
      </NewDesignBasicPage>
    );

  if (isError || !response)
    return (
      <NewDesignBasicPage>
        <div>Ошибка загрузки</div>
      </NewDesignBasicPage>
    );

  const { data } = response;
  const createdDate = new Date(data.attributes.createdAt);

  const thumbnail = data.attributes.thumbnail.data.attributes;
  const poster =
    thumbnail.previewUrl ||
    thumbnail.formats.thumbnail?.url ||
    thumbnail.formats.small?.url ||
    thumbnail.formats.medium?.url ||
    thumbnail.formats.large?.url ||
    thumbnail.formats.large?.url ||
    thumbnail.url;

  return (
    <NewDesignBasicPage>
      <div className={css.wrapper}>
        <div className={css.inner}>
          <VideoPlayer
            src={`${REACT_APP_API_ENDPOINT}${data.attributes.video.data.attributes.url.substring(
              1
            )}`}
            poster={`${REACT_APP_API_ENDPOINT}${poster.substring(1)}`}
          />
          <h1>{data.attributes.title}</h1>
          <div className={css.mainInfo}>
            <p className={css.date}>{formatDate(createdDate)}</p>
            <p className={css.views}>
              <span>{compactNumber(data.attributes.views)}</span> <EyeIcon />
            </p>
          </div>
          <p>{data.attributes.description}</p>
        </div>
      </div>
    </NewDesignBasicPage>
  );
}

export default Video;
