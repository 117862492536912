import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AboutPage from "./page/AboutPage";
import DataProcessingPolicy from "./page/DataProcessingPolicy";
import DonationsPage from "./page/DonationsPage";
import PhotosPage from "./page/PhotoPage";
import PrivacyPolicy from "./page/PrivacyPolicy";
import Stories from "./page/Stories";
import Story from "./page/Story";
import UserAgreement from "./page/UserAgreement";
import Video from "./page/Video";
import VideosPage from "./page/VideosPage";

function AppRouterReduced() {
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/stories">
        <Route index element={<Stories />} />
        <Route path="/stories/:storyId" element={<Story />} />
      </Route>
      <Route path="/about" element={<AboutPage />} />

      <Route path="/photo" element={<PhotosPage />} />
      <Route path="/video">
        <Route index element={<VideosPage />} />
        <Route path="/video/:videoId" element={<Video />} />
      </Route>

      <Route path="/donations" element={<DonationsPage />} />

      <Route path="/user-agreement" element={<UserAgreement />} />
      <Route
        path="/data-processing-policy"
        element={<DataProcessingPolicy />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route path="*" element={<Navigate to="/stories" />} />
    </Routes>
  );
}

export default AppRouterReduced;
