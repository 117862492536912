import { ReactNode } from "react";
import HandyText from "../../atoms/HandyText";
import PageTitle from "../../atoms/PageTitle";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import css from "./DataProcessingPolicy.module.css";

const content: {
  content: ReactNode;
  title: string;
  anchor: string;
}[] = [
  {
    title: "Общие положения",
    anchor: "osnovnye-polozheniya",
    content: (
      <>
        <p>
          1. Политика Автономной Некоммерческой организации по Развитию
          Образовательной, Культурной и Исследовательской Деятельности
          "Поколение Уверенного Будущего" (далее по тексту – Организация,
          Оператор) в отношении обработки персональных данных определяет базовые
          принципы, которыми Организация руководствуется в вопросах обеспечения
          защиты персональных данных при их обработке.
        </p>
        <p>
          2. Настоящий документ, разработанный на основании Конституции
          Российской Федерации, Гражданского кодекса Российской Федерации,
          федеральных законов от 27.07.2006 № 152-ФЗ «О персональных данных»
          (далее – Федеральный закон «О персональных данных»), от 27.07.2006 №
          149-ФЗ «Об информации, информационных технологиях и о защите
          информации» и иных нормативных правовых актов Российской Федерации и
          локальных нормативных актов Организации, устанавливает единые цели,
          принципы и правила обработки персональных данных в Организации и
          определяет основные меры, реализуемые Организацией для обеспечения
          защиты персональных данных.
        </p>
        <p>
          3. Организация, являясь оператором, осуществляющим обработку
          персональных данных, обеспечивает защиту прав и свобод субъектов при
          обработке их персональных данных и принимает меры для обеспечения
          выполнения обязанностей, предусмотренных Федеральным законом «О
          персональных данных» и принятыми в соответствии с ним нормативными
          правовыми актами.
        </p>
        <p>
          4. Настоящий документ является общедоступным и подлежит размещению на
          официальном сайте Организации в информационно-телекоммуникационной
          сети Интернет (далее – сеть Интернет).
        </p>
      </>
    ),
  },
  {
    title: "Основные понятия",
    anchor: "osnovnye-ponyaniya",
    content: (
      <>
        <p>В настоящем документе используются следующие понятия:</p>
        <p>
          <b>персональные данные</b> – любая информация, относящаяся к прямо или
          косвенно определенному или определяемому физическому лицу (субъекту
          персональных данных);
        </p>
        <p>
          <b>
            персональные данные, разрешенные субъектом персональных данных для
            распространения
          </b>{" "}
          – персональные данные, доступ неограниченного круга лиц к которым
          предоставлен субъектом персональных данных путем дачи согласия на
          обработку персональных данных, разрешенных субъектом персональных
          данных для распространения в порядке, предусмотренном Федеральным
          законом «О персональных данных»;
        </p>
        <p>
          <b>оператор</b> – государственный орган, муниципальный орган,
          юридическое или физическое лицо, самостоятельно или совместно с
          другими лицами организующее и (или) осуществляющее обработку
          персональных данных, а также определяющее цели обработки персональных
          данных, состав персональных данных, подлежащих обработке, действия
          (операции), совершаемые с персональными данными;
        </p>
        <p>
          <b>обработка персональных данных</b> – любое действие (операция) или
          совокупность действий (операций), совершаемых с использованием средств
          автоматизации или без использования таких средств с персональными
          данными, включая сбор, запись, систематизацию, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование, передачу
          (распространение, предоставление, доступ), обезличивание,
          блокирование, удаление, уничтожение персональных данных;
        </p>
        <p>
          <b>автоматизированная обработка персональных данных</b> – обработка
          персональных данных с помощью средств вычислительной техники;
        </p>
        <p>
          <b>распространение персональных данных</b> – действия, направленные на
          раскрытие персональных данных неопределенному кругу лиц;
        </p>
        <p>
          <b>предоставление персональных данных</b> – действия, направленные на
          раскрытие персональных данных определенному лицу или определенному
          кругу лиц;
        </p>
        <p>
          <b>блокирование персональных данных</b> – временное прекращение
          обработки персональных данных (за исключением случаев, если обработка
          необходима для уточнения персональных данных);
        </p>
        <p>
          <b>уничтожение персональных данных</b> – действия, в результате
          которых становится невозможным восстановить содержание персональных
          данных в информационной системе и (или) в результате которых
          уничтожаются материальные носители персональных данных;
        </p>
        <p>
          <b>обезличивание персональных данных</b> – действия, в результате
          которых становится невозможным без использования дополнительной
          информации определить принадлежность персональных данных конкретному
          субъекту персональных данных;
        </p>
        <p>
          <b>информационная система персональных данных</b> – совокупность
          содержащихся в базах данных персональных данных и обеспечивающих их
          обработку информационных технологий и технических средств;
        </p>
        <p>
          <b>трансграничная передача персональных данных</b> – передача
          персональных данных на территорию иностранного государства органу
          власти иностранного государства, иностранному физическому лицу или
          иностранному юридическому лицу. Иные термины и определения
          используются в настоящем документе в значениях, определенных
          Федеральным законом «О персональных данных» и принятыми в соответствии
          с ним нормативно-правовыми актами.
        </p>
      </>
    ),
  },
  {
    title: "Права и обязанности Оператора",
    anchor: "prava-i-obyazannosti-operatora",
    content: (
      <>
        <p>
          1. Оператор вправе: с согласия субъекта персональных данных поручить
          обработку его персональных данных другому лицу, если иное не
          предусмотрено Федеральным законом «О персональных данных», на
          основании заключаемого с этим лицом договора; предоставлять
          персональные данные субъектов третьим лицам, если это предусмотрено
          законодательством Российской Федерации (передавать персональные данные
          органам дознания, следствия, иным уполномоченным органам по
          основаниям, предусмотренным законодательством Российской Федерации);
          отказывать в предоставлении персональных данных в случаях,
          предусмотренных законодательством Российской Федерации; использовать
          персональные данные субъекта без его согласия в случаях,
          предусмотренных законодательством Российской Федерации.
        </p>
        <p>
          2. Оператор обязан: сообщать субъекту персональных данных или его
          представителю информацию о наличии персональных данных, относящихся к
          соответствующему субъекту персональных данных, а также предоставлять
          возможность ознакомления с этими персональными данными при обращении
          субъекта персональных данных или его представителя либо в течение 30
          календарных дней с даты получения запроса субъекта персональных данных
          или его представителя; принимать меры, необходимые и достаточные для
          обеспечения выполнения обязанностей оператора, предусмотренных
          Федеральным законом «О персональных данных» и принятыми в соответствии
          с ним нормативными правовыми актами.
        </p>
      </>
    ),
  },
  {
    title: "Права субъектов персональных данных",
    anchor: "prava-subyektov-personalnikh-dannikh",
    content: (
      <>
        <p>Субъекты персональных данных имеют право на:</p>
        <ul>
          <li>
            полную информацию об их персональных данных, обрабатываемых
            Оператором;
          </li>
          <li>
            доступ к своим персональным данным, включая право на получение копии
            любой записи, содержащей их персональные данные, за исключением
            случаев, предусмотренных законодательством Российской Федерации;
          </li>
          <li>
            уточнение своих персональных данных, их блокирование или уничтожение
            в случае, если персональные данные являются неполными, устаревшими,
            неточными, незаконно полученными или не являются необходимыми для
            заявленной цели обработки;
          </li>
          <li>отзыв согласия на обработку персональных данных;</li>
          <li>
            принятие предусмотренных законодательством Российской Федерации мер
            по защите своих прав;
          </li>
          <li>
            обжалование в уполномоченный орган по защите прав субъектов
            персональных данных или в судебном порядке неправомерных действий
            или бездействия при обработке и защите его персональных данных;
          </li>
          <li>
            осуществление иных прав, предусмотренных законодательством
            Российской Федерации.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "Цели обработки персональных данных",
    anchor: "celi-obrabotki-personalnikh-dannikh",
    content: (
      <>
        <p>Обработка персональных данных осуществляется в целях:</p>
        <ul>
          <li>
            обеспечения прав и законных интересов Оператора в рамках
            осуществляемых им видов деятельности;
          </li>
          <li>
            обеспечения соблюдения требований Конституции Российской Федерации,
            законодательных и иных нормативных правовых актов Российской
            Федерации, локальных нормативных актов Оператора;
          </li>
          <li>
            регулирования трудовых отношений с работниками Оператора или с
            третьими лицами в случаях, предусмотренных трудовым
            законодательством Российской Федерации (в том числе содействия в
            трудоустройстве, обучении работников, контроля количества и качества
            выполняемой работы, обеспечения сохранности имущества Организации);
          </li>
          <li>
            предоставления работникам Оператора и членам их семей дополнительных
            гарантий и компенсаций;
          </li>
          <li>
            организации и обеспечения пропускного и внутриобъектового режимов на
            объектах Оператора;
          </li>
          <li>
            подготовки, заключения, исполнения и прекращения Оператором
            договоров с контрагентами;
          </li>
          <li>
            выполнения функций, полномочий и обязанностей, возложенных на
            Оператора законодательством Российской Федерации;
          </li>
          <li>
            исполнения Оператором судебных актов, актов других органов или
            должностных лиц, подлежащих исполнению в соответствии с
            законодательством Российской Федерации об исполнительном
            производстве;
          </li>
          <li>
            предоставления консультационных, методических и информационных
            услуг;
          </li>
          <li>
            организации проведения форумов, выставок, семинаров, конференций,
            совещаний, встреч, симпозиумов;
          </li>
          <li>
            обучения<sup>1</sup>, организации обучения и повышения квалификации
            специалистов в сфере интернет-технологий, создание и реализация
            просветительских программ в области развития проектов, реализуемых в
            сети Интернет;
          </li>
          <li>в иных законных целях.</li>
        </ul>
      </>
    ),
  },
  {
    title: "Правовые основания обработки персональных данных",
    anchor: "pravoviye-osnovaniya-obrabotki-personalnikh-dannikh",
    content: (
      <ul>
        <li>Трудовой кодекс Российской Федерации;</li>
        <li>Гражданский кодекс Российской Федерации;</li>
        <li>Налоговый кодекс Российской Федерации;</li>
        <li>Семейный кодекс Российской Федерации;</li>
        <li>
          Согласие субъекта персональных данных на обработку персональных
          данных;
        </li>
        <li>
          Федеральный закон от 19.05.1995 № 81-ФЗ «О государственных пособиях
          гражданам, имеющим детей»;
        </li>
        <li>
          Федеральный закон от 24.11.1995 № 181-ФЗ «О социальной защите
          инвалидов в Российской Федерации»;
        </li>
        <li>
          Федеральный закон от 01.04.1996 № 27-ФЗ «Об индивидуальном
          (персонифицированном) учете в системе обязательного пенсионного
          страхования»;
        </li>
        <li>
          Федеральный закон от 26.02.1997 № 31-ФЗ «О мобилизационной подготовке
          и мобилизации в Российской Федерации»;
        </li>
        <li>
          Федеральный закон от 28.03.1998 № 53-ФЗ «О воинской обязанности и
          военной службе»;
        </li>
        <li>
          Федеральный закон от 24.07.1998 № 125-ФЗ «Об обязательном социальном
          страховании от несчастных случаев на производстве и профессиональных
          заболеваний»;
        </li>
        <li>
          Федеральный закон от 16.07.1999 № 165-ФЗ «Об основах обязательного
          социального страхования»;
        </li>
        <li>
          Федеральный закон от 15.12.2001 № 166-ФЗ «О государственном пенсионном
          обеспечении в Российской Федерации»;
        </li>
        <li>
          Федеральный закон от 15.12.2001 № 167-ФЗ «Об обязательном пенсионном
          страховании в Российской Федерации»;
        </li>
        <li>
          Федеральный закон от 17.12.2001 № 173-ФЗ «О трудовых пенсиях в
          Российской Федерации»;
        </li>
        <li>Федеральный закон от 07.07.2003 № 126-ФЗ «О связи».</li>
        <li>
          Федеральный закон от 29.12.2006 № 255-ФЗ «Об обязательном социальном
          страховании на случай временной нетрудоспособности и в связи с
          материнством»;
        </li>
        <li>
          Федеральный закон от 29.11.2010 № 326-ФЗ «Об обязательном медицинском
          страховании в Российской Федерации»;
        </li>
        <li>
          Федеральный закон от 21.11.2011 № 323-ФЗ «Об основах охраны здоровья
          граждан в Российской Федерации»;
        </li>
        <li>
          Федеральный закон от 06.12.2011 № 402-ФЗ «О бухгалтерском учете»;
        </li>
        <li>Федеральный закон от 28.12.2013 № 400-ФЗ «О страховых пенсиях»;</li>
        <li>
          постановление Правительства Российской Федерации от 27.11.2006 2006
          № 719 «Об утверждении Положения о воинском учете»;
        </li>
        <li>
          согласие на обработку персональных данных (в т.ч. согласие на
          обработку персональных данных соискателей на замещение вакантных
          должностей; согласие пользователей сайта; согласие иных субъектов
          персональных данных; согласие на обработку персональных данных,
          разрешенных субъектом персональных данных для распространения);
        </li>
        <li>
          договоры, заключаемые между Оператором и субъектом персональных
          данных;
        </li>
        <li>
          иные нормативные правовые акты, регулирующие правоотношения в области
          обработки персональных данных.
        </li>
      </ul>
    ),
  },
  {
    title: "Категории обрабатываемых персональных данных и категории субъектов",
    anchor: "kategorii-subjektov-personalnikh-dannikh-i-kategorii-subjektov",
    content: (
      <>
        <p>
          1.Для реализации целей обработки, указанных в настоящей Политике,
          Оператор осуществляет обработку персональных данных своих работников,
          а также иных субъектов персональных данных, не состоящих в трудовых
          отношениях с Оператором, в том числе:
        </p>
        <ul>
          <li>
            бывших работников, а также их близких родственников и представителей
            работников Оператора;
          </li>
          <li>кандидатов на трудоустройство;</li>
          <li>работников и представителей контрагентов Оператора;</li>
          <li>
            посетителей объектов Оператора и участников проводимых Оператором
            мероприятий;
          </li>
          <li>
            субъектов, персональные данные которых переданы Оператору третьими
            лицами в рамках заключенных договоров, а также в целях заключения
            договоров, проведения конкурсных, закупочных процедур, организации
            массовых (публичных) мероприятий.
          </li>
          <li>пользователей информационных систем Оператора.</li>
        </ul>
        <p>
          2. Перечень персональных данных, обрабатываемых Оператором,
          определяется в соответствии с законодательством Российской Федерации и
          локальными нормативными документами Оператора с учетом целей обработки
          персональных данных, указанных в настоящей Политике.
        </p>
        <p>
          3. Оператор не имеет права получать и обрабатывать персональные
          данные, касающихся расовой, национальной принадлежности, политических
          взглядов, религиозных или философских убеждений, состояния здоровья,
          интимной жизни, за исключением случаев, предусмотренных Федеральным
          законом «О персональных данных».
        </p>
        <p>
          4. В целях информационного обеспечения Оператор может создавать
          общедоступные источники (в том числе справочники и адресные книги), в
          которые могут включаться персональные данные, сообщаемые субъектом
          персональных данных с его письменного согласия, если иное не
          предусмотрено законодательством Российской Федерации.
        </p>
        <p>
          5. Оператором могут обрабатываться биометрические персональные данные
          субъектов персональных данных при наличии письменного согласия
          субъектов, если иное не предусмотрено законодательством Российской
          Федерации.
        </p>
      </>
    ),
  },
  {
    title: "Порядок и условия обработки персональных данных",
    anchor: "poryadok-i-usloviya-obrabotki-personalnikh-dannikh",
    content: (
      <>
        <p>
          1. Оператор осуществляет сбор, запись, систематизацию, накопление,
          хранение, уточнение (обновление, изменение), извлечение,
          использование, передачу (распространение, предоставление, доступ),
          обезличивание, блокирование, удаление и уничтожение персональных
          данных.
        </p>
        <p>
          2. Обработка персональных данных осуществляется следующими способами:
        </p>
        <ul>
          <li>неавтоматизированная обработка персональных данных;</li>
          <li>
            автоматизированная обработка персональных данных с передачей
            информации по информационно-телекоммуникационным сетям или без
            таковой;
          </li>
          <li>смешанная обработка персональных данных.</li>
        </ul>
        <p>
          3. Обработка персональных данных Оператором осуществляется с согласия
          субъекта персональных данных на обработку его персональных данных,
          если иное не предусмотрено законодательством Российской Федерации.
        </p>
        <p>
          4. Хранение персональных данных субъектов персональных данных
          осуществляется в форме, позволяющей определить субъекта персональных
          данных, не дольше, чем этого требуют цели их обработки, если срок
          хранения персональных данных не установлен федеральным законом,
          договором, стороной которого, выгодоприобретателем или поручителем по
          которому является субъект персональных данных.
        </p>
        <p>
          5. Условиями прекращения обработки персональных данных могут являться:
        </p>
        <ul>
          <li>истечение срока обработки персональных данных; </li>
          <li>достижение целей обработки персональных данных; </li>
          <li>
            обращение субъекта с запросом на уничтожение или отзывом согласия на
            обработку персональных данных (для персональных данных, полученных
            от субъекта);
          </li>
          <li>
            обращение оператора (контрагента) с запросом на уничтожение
            персональных данных субъекта (для персональных данных, полученных в
            рамках поручения на обработку);
          </li>
          <li>
            прекращение деятельности Оператора (ликвидация или реорганизация).
          </li>
        </ul>
        <p>
          6. При обработке персональных данных Оператор принимает необходимые и
          достаточные правовые, организационные и технические меры для защиты
          персональных данных от неправомерного или случайного доступа к ним,
          уничтожения, изменения, блокирования, копирования, предоставления,
          распространения персональных данных, а также от иных неправомерных
          действий в отношении персональных данных, в том числе:
        </p>
        <ul>
          <li>
            назначение лица, ответственного за организацию обработки
            персональных данных;
          </li>
          <li>
            принятие локальных нормативных актов и иных документов в области
            обработки и защиты персональных данных;
          </li>
          <li>
            определение угроз безопасности персональных данных при их обработке
            в информационных системах персональных данных;
          </li>
          <li>
            применение организационных и технических мер по обеспечению
            безопасности персональных данных при их обработке в информационных
            системах персональных данных;
          </li>
          <li>
            ознакомление работников Оператора, непосредственно осуществляющих
            обработку персональных данных, с положениями законодательства
            Российской Федерации о персональных данных и локальными нормативными
            актами Организации по вопросам защиты персональных данных, при
            необходимости, организация соответствующего обучения указанных
            работников;
          </li>
          <li>
            получение согласий субъектов персональных данных на обработку их
            персональных данных, за исключением случаев, предусмотренных
            законодательством Российской Федерации;
          </li>
          <li>
            осуществление внутреннего контроля за принимаемыми мерами по
            обеспечению безопасности персональных данных;
          </li>
          <li>
            иные меры, предусмотренные законодательством Российской Федерации в
            области обработки персональных данных.
          </li>
        </ul>
        <p>
          7. Работники Оператора, виновные в нарушении требований Федерального
          закона «О персональных данных» и принятых в соответствии с ним
          нормативных правовых актов и локальных нормативных актов, несут
          материальную, дисциплинарную, административную, гражданско-правовую
          или уголовную ответственность в порядке, установленном
          законодательством Российской Федерации.
        </p>
      </>
    ),
  },
  {
    title: "Уточнение, исправление, удаление и уничтожение персональных данных",
    anchor:
      "utochnenie-ispravlenie-udalenie-i-unichtozhenie-personalnikh-dannikh",
    content: (
      <>
        <p>
          1. В случае подтверждения факта неточности персональных данных,
          персональные данные подлежат уточнению Оператором, а в случае
          подтверждения факта неправомерности их обработки – обработка должна
          быть прекращена.
        </p>
        <p>
          2. При достижении целей обработки персональных данных, а также в
          случае отзыва субъектом персональных данных согласия на их обработку
          Оператор обязан прекратить их обработку или обеспечить прекращение
          такой обработки (если обработка персональных данных осуществляется
          другим лицом, действующим по поручению Оператора) и в случае, если
          сохранение персональных данных более не требуется для целей обработки
          персональных данных, уничтожить персональные данные или обеспечить их
          уничтожение (если обработка персональных данных осуществляется другим
          лицом, действующим по поручению оператора) в срок, не превышающий 30
          календарных дней с даты поступления указанного отзыва, если:
        </p>
        <ul>
          <li>
            иное не предусмотрено договором, стороной которого,
            выгодоприобретателем или поручителем, по которому является субъект
            персональных данных;
          </li>
          <li>
            Оператор не вправе осуществлять обработку без согласия субъекта
            персональных данных на основаниях, предусмотренных Федеральным
            законом «О персональных данных» или иными федеральными законами;
          </li>
          <li>
            иное не предусмотрено соглашением между Оператором и субъектом
            персональных данных.
          </li>
        </ul>
        <p>
          3. В случае отсутствия возможности уничтожения персональных данных в
          течение срока, указанного в вышеуказанном пункте, Оператор
          осуществляет блокирование таких персональных данных и обеспечивает
          уничтожение персональных данных в срок не более 6 (шести) месяцев,
          если иной срок не установлен федеральными законами.
        </p>
        <p>
          4. Для осуществления своих прав субъект персональных данных может
          лично или через законного представителя обратиться в Автономную
          некоммерческую организацию «Поколение уверенного будущего» путем
          uнаправления письменного запроса по адресу, указанному на официальном
          сайте АНО «Поколение уверенного будущего» geroyregionov.ru.
          Рассмотрение запросов осуществляется в порядке, установленном
          законодательством Российской Федерации.
        </p>
        <p>
          1 - Образовательная деятельность осуществляется в порядке,
          предусмотренном статьей 31 Федерального закона от 29.12.2012 г. №
          273-ФЗ «Об образовании в Российской Федерации».
        </p>
      </>
    ),
  },
];

function DataProcessingPolicy() {
  return (
    <NewDesignBasicPage>
      <div className={css.wrapper}>
        <aside className={css.aside}>
          <nav className={css.nav}>
            <h2>Оглавление</h2>
            <ul>
              {content.map((entry) => (
                <li key={entry.anchor}>
                  <a href={`#${entry.anchor}`}>{entry.title}</a>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
        <main className={css.content}>
          <PageTitle>
            Политика в отношении обработки персональных данных
          </PageTitle>
          <HandyText>
            Автономная Некоммерческая организация по Развитию Образовательной,
            Культурной и Исследовательской Деятельности "Поколение Уверенного
            Будущего"
          </HandyText>
          {content.map((entry) => (
            <section key={entry.anchor} id={entry.anchor}>
              <h2>{entry.title}</h2>
              {entry.content}
            </section>
          ))}
        </main>
      </div>
    </NewDesignBasicPage>
  );
}

export default DataProcessingPolicy;
