import { useGetManyNewsQuery } from "../../apis/news";
import Spinner from "../../atoms/Spinner";
import NewsCard from "../../molecules/NewsCard";
import { buildComplexClassName } from "../../utils";
import css from "./NewsPreview.module.css";

interface INewsPreviewProps {
  className?: string;
}

function NewsPreview({ className }: INewsPreviewProps) {
  const { data: news, isLoading } = useGetManyNewsQuery({ start: 0, limit: 2 });

  if (isLoading)
    return (
      <div className={buildComplexClassName(css.wrapper, className)}>
        <Spinner className={css.center} />
      </div>
    );

  return (
    <div className={buildComplexClassName(className)}>
      {news && news.data.length > 0 ? (
        news.data.map((entry) => (
          <NewsCard key={entry.id} className={css.item} {...entry.attributes} />
        ))
      ) : (
        <p className={css.center}>Новостей пока нет</p>
      )}
    </div>
  );
}

export default NewsPreview;
