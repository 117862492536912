import { Children, ReactNode } from "react";
import { buildComplexClassName } from "../../utils";
import css from "./BreadCrumbs.module.css";

interface IBreadCrumbsProps {
  className?: string;
  items: ReactNode[];
}

function BreadCrumbs({ items, className }: IBreadCrumbsProps) {
  return (
    <div className={buildComplexClassName(css.wrapper, className)}>
      {Children.toArray(
        items.map((item, index) => (
          <>
            {item}
            {index + 1 < items.length && (
              <span className={css.separator}>/</span>
            )}
          </>
        ))
      )}
    </div>
  );
}

export default BreadCrumbs;
