import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAppDispatch } from "./hooks/redux";
import AboutPage from "./page/AboutPage";
import DataProcessingPolicy from "./page/DataProcessingPolicy";
import DonationsPage from "./page/DonationsPage";
import ForgotPasswordPage from "./page/ForgotPasswordPage";
import Index from "./page/Index";
import LoginPage from "./page/LoginPage";
import MediumList from "./page/MediumList";
import NewsListPage from "./page/NewsListPage";
import NewsPage from "./page/NewsPage";
import NewStoryPage from "./page/NewStoryPage";
import PhotosPage from "./page/PhotoPage";
import PrivacyPolicy from "./page/PrivacyPolicy";
import ProfileStories from "./page/ProfileStories";
import ProtectedRoutePage from "./page/ProtectedRoutePage";
import SignInPage from "./page/SignInPage";
import Stories from "./page/Stories";
import Story from "./page/Story";
import UserAgreement from "./page/UserAgreement";
import Video from "./page/Video";
import VideosPage from "./page/VideosPage";
import { loginFromLocal } from "./slices/auth";

function AppRouter() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(loginFromLocal());
  }, [dispatch]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/news">
        <Route path="/news/:newsUrl" element={<NewsPage />} />
        <Route index element={<NewsListPage />} />
      </Route>
      <Route path="/medium">
        <Route index element={<MediumList />} />
      </Route>
      <Route path="/stories">
        <Route index element={<Stories />} />
        <Route path="/stories/:storyId" element={<Story />} />
      </Route>
      <Route path="/about" element={<AboutPage />} />

      <Route path="/photo" element={<PhotosPage />} />
      <Route path="/video">
        <Route index element={<VideosPage />} />
        <Route path="/video/:videoId" element={<Video />} />
      </Route>

      <Route path="/media" element={<Navigate to="/photo" />} />

      <Route path="/login" element={<LoginPage />} />
      <Route path="/sign-up" element={<SignInPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />

      <Route path="/profile" element={<ProtectedRoutePage />}>
        <Route path="/profile/stories" element={<ProfileStories />} />
        <Route path="/profile/new-story" element={<NewStoryPage />} />
      </Route>
      <Route path="/donations" element={<DonationsPage />} />

      <Route path="/user-agreement" element={<UserAgreement />} />
      <Route
        path="/data-processing-policy"
        element={<DataProcessingPolicy />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route path="/" element={<Index />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default AppRouter;
