import { ReactNode } from "react";
import BrandedHeader from "../../molecules/BrandedHeader";
import css from "./FormPage.module.css";

interface IBasicPageProps {
  children: ReactNode;
  title: ReactNode;
  description?: ReactNode;
}

function FormPage({ children, title, description }: IBasicPageProps) {
  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <BrandedHeader className={css.header} description={description}>
          {title}
        </BrandedHeader>
        <main className={css.card}>{children}</main>
      </div>
    </div>
  );
}

export default FormPage;
