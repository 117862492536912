import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import tippy from "tippy.js";
import Avatar from "../../atoms/Avatar";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import css from "./Account.module.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import Spinner from "../../atoms/Spinner";
import { ReactComponent as InfoIcon } from "css.gg/icons/svg/info.svg";
import { ReactComponent as MoreIcon } from "css.gg/icons/svg/more-vertical-alt.svg";
import { ReactComponent as CloseIcon } from "css.gg/icons/svg/close.svg";
import { logout } from "../../slices/auth";

function Account() {
  const { userData, isLoading } = useAppSelector((s) => ({
    userData: s.auth.user,
    isLoading: s.auth.isAuthProcess,
  }));
  const [isUserOpened, setIsUserOpened] = useState(false);
  const confirmAlertRef = useRef<SVGSVGElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const { current } = confirmAlertRef;
    if (!current || userData?.confirmed) return;

    const instance = tippy(current, {
      content:
        "Вам на почту отправлено письмо со ссылкой на активацию аккаунта",
      theme: "light",
    });

    return instance.destroy;
  }, [userData]);

  if (isLoading) return <Spinner className={css.loading} />;

  if (!userData)
    return (
      <Link to="/login" className={css.aloneLink}>
        <span>Войти</span>
      </Link>
    );

  return (
    <>
      <div className={css.header}>
        <Avatar
          className={css.avatar}
          fullName={userData?.fullName || "Без имени"}
        />
        <div className={css.content}>
          {userData.confirmed ? (
            <div className={css.names}>
              <span>{userData?.fullName || "Без имени"}</span>
              <span className={css.email}>
                {userData?.email || "Нет адреса"}
              </span>
            </div>
          ) : (
            <span className={css.alert}>Аккаунт не активирован</span>
          )}
        </div>
        {!userData.confirmed ? (
          <InfoIcon style={{ alignSelf: "center" }} ref={confirmAlertRef} />
        ) : (
          <button
            title={isUserOpened ? "Спрятать ссылки" : "Показать ссылки"}
            className={css.moreButton}
            onClick={() => setIsUserOpened((p) => !p)}
          >
            {isUserOpened ? <CloseIcon /> : <MoreIcon />}
          </button>
        )}
      </div>
      <div className={css.links}>
        {!userData.confirmed ? (
          <Link to="/login" className={css.link}>
            <span>Войти</span>
          </Link>
        ) : (
          isUserOpened && (
            <>
              <Link className={css.link} to="/profile/new-story">
                <span>Новая история</span>
              </Link>
              <Link className={css.link} to="/profile/stories">
                <span>Мои истории</span>
              </Link>
              <button
                className={css.link}
                type="button"
                onClick={() => {
                  navigate("/");
                  dispatch(logout());
                }}
              >
                <span>Выйти</span>
              </button>
            </>
          )
        )}
      </div>
    </>
  );
}

export default Account;
