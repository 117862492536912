import { Link, useLocation } from "react-router-dom";
import { buildComplexClassName } from "../../utils";
import css from "./StoriesTabsHeader.module.css";

enum OwnStoriesPage {
  NewStory,
  StoriesList,
}

interface IStoriesTabsHeaderProps {
  className?: string;
}

function StoriesTabsHeader({ className }: IStoriesTabsHeaderProps) {
  const { pathname } = useLocation();
  let activeSection: OwnStoriesPage | null = null;
  if (pathname === "/profile/new-story")
    activeSection = OwnStoriesPage.NewStory;
  if (pathname === "/profile/stories")
    activeSection = OwnStoriesPage.StoriesList;

  return (
    <div className={buildComplexClassName(css.wrapper, className)}>
      <div className={css.content}>
        <Link
          aria-disabled={activeSection === OwnStoriesPage.StoriesList}
          className={buildComplexClassName(
            css.link,
            activeSection === OwnStoriesPage.StoriesList && css.active
          )}
          to="/profile/stories"
        >
          Мои истории
        </Link>
        <Link
          aria-disabled={activeSection === OwnStoriesPage.NewStory}
          className={buildComplexClassName(
            css.link,
            activeSection === OwnStoriesPage.NewStory && css.active
          )}
          to="/profile/new-story"
        >
          Новая история
        </Link>
      </div>
    </div>
  );
}

export default StoriesTabsHeader;
