import css from "./PhotosPage.module.css";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import MediaTabsHeader from "../../molecules/MediaTabsHeader";
import { FoundPhotos, useLazyGetPhotosQuery } from "../../apis/media";
import { useEffect, useState } from "react";
import Spinner from "../../atoms/Spinner";
import Button from "../../atoms/Button";
import Zoom from "react-medium-image-zoom";
import { buildSrcSet, formatDate } from "../../utils";
import "react-medium-image-zoom/dist/styles.css";
import ItemsGrid from "../../molecules/ItemsGrid";

const PAGE_STEP = 6;
const { REACT_APP_API_ENDPOINT } = process.env;

function PhotosPage() {
  const [fetchPhotos] = useLazyGetPhotosQuery();
  const [start, setStart] = useState(0);
  const [photos, setPhotos] = useState<FoundPhotos[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchPhotos({ start: start, limit: PAGE_STEP })
      .then(({ data: response }) => {
        if (!response) return;
        const { data, meta } = response || {};

        const { total } = meta.pagination;
        setIsLastPage(total <= start + PAGE_STEP);
        setPhotos((prevNews) => {
          if (start === 0) return data;
          return [...prevNews, ...data];
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchPhotos, start]);

  return (
    <NewDesignBasicPage>
      <MediaTabsHeader className={css.header} />
      <div className={css.wrapper}>
        <div className={css.inner}>
          {photos.length === 0 ? (
            <p>Тут пока пусто</p>
          ) : (
            <>
              <div className={css.list}></div>
              <ItemsGrid items={photos}>
                {(photo) => (
                  <>
                    <div className={css.image}>
                      <Zoom>
                        <img
                          src={`${REACT_APP_API_ENDPOINT}${photo.attributes.image.data.attributes.url.substring(
                            1
                          )}`}
                          srcSet={buildSrcSet(
                            photo.attributes.image.data.attributes
                          )}
                          alt={photo.attributes.image.data.attributes.caption}
                        />
                      </Zoom>
                    </div>
                    <p className={css.date}>
                      {formatDate(new Date(photo.attributes.createdAt))}
                    </p>
                    <h2 className={css.title}>{photo.attributes.title}</h2>
                  </>
                )}
              </ItemsGrid>
              {!isLastPage && (
                <Button
                  disabled={isLoading}
                  className={css.button}
                  type="button"
                  onClick={() => setStart((p) => p + PAGE_STEP)}
                >
                  Загрузить еще
                </Button>
              )}
            </>
          )}
          {isLoading && (
            <div className={css.spinner}>
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </NewDesignBasicPage>
  );
}

export default PhotosPage;
