import { Link, useLocation } from "react-router-dom";
import { buildComplexClassName } from "../../utils";
// import xmasAttributeIcon from "../../assets/icons/xmas.svg";
import css from "./Navbar.module.css";

enum NavbarItems {
  Main,
  Donations,
  News,
  Stories,
  // Competitions,
  MassMedium,
  About,
  // Contacts,
  Media,
}

const ITEMS =
  process.env.REACT_APP_BUILD_TARGET === "reduced"
    ? [
        {
          code: NavbarItems.Stories,
          path: "/stories",
          name: "Герои регионов",
        },
        {
          code: NavbarItems.Donations,
          path: "/donations",
          name: "Пожертвования",
        },
        { code: NavbarItems.Media, path: "/photo", name: "Фото и видео" },
        {
          code: NavbarItems.About,
          path: "/about",
          name: "О нас",
        },
      ]
    : [
        {
          code: NavbarItems.Main,
          path: "/",
          name: "Главная",
        },
        {
          code: NavbarItems.Donations,
          path: "/donations",
          name: "Пожертвования",
        },
        {
          code: NavbarItems.News,
          path: "/news",
          name: "Новости",
        },
        {
          code: NavbarItems.Stories,
          path: "/stories",
          name: "Герои регионов",
        },
        // {
        //   code: NavbarItems.Competitions,
        //   path: "/competitions",
        //   name: "Конкурсы",
        // },
        {
          code: NavbarItems.MassMedium,
          path: "/medium",
          name: "Публикации в СМИ",
        },
        { code: NavbarItems.Media, path: "/photo", name: "Фото и видео" },
        {
          code: NavbarItems.About,
          path: "/about",
          name: "О нас",
        },
        // {
        //   code: NavbarItems.Contacts,
        //   path: "/contacts",
        //   name: "Контакты",
        // },
      ];

interface INavbarProps {
  className?: string;
}

function Navbar({ className }: INavbarProps) {
  const { pathname } = useLocation();
  const activeSection = (() => {
    if (pathname === "/") return NavbarItems.Main;
    if (pathname.startsWith("/donations")) return NavbarItems.Donations;
    // if (pathname === "/contacts") return NavbarItems.Contacts;
    if (pathname === "/about") return NavbarItems.About;
    if (pathname.startsWith("/news")) return NavbarItems.News;
    if (pathname.startsWith("/stories")) return NavbarItems.Stories;
    if (pathname.startsWith("/photo") || pathname.startsWith("/video"))
      return NavbarItems.Media;
    // if (pathname.startsWith("/competitions")) return NavbarItems.Competitions;
    if (pathname.startsWith("/medium")) return NavbarItems.MassMedium;

    return null;
  })();

  return (
    <nav className={buildComplexClassName(css.wrapper, className)}>
      {/* <a
        target="_blank"
        rel="noreferrer"
        className={css.link}
        href="https://story.geroyregionov.ru/noviygodvnovieregioni"
      >
        <span className={css.linkContent}>
          <img
            src={xmasAttributeIcon}
            alt="Ёлочная игрушка"
            className={css.linkContentIcon}
          />{" "}
          Новогодняя акция{" "}
          <img
            src={xmasAttributeIcon}
            alt="Ёлочная игрушка"
            className={css.linkContentIcon}
          />
        </span>
      </a> */}
      {ITEMS.map(({ path, code, name }) => (
        <Link
          className={buildComplexClassName(
            css.link,
            activeSection === code && css.selected
          )}
          to={path}
          key={code}
        >
          <span className={css.linkContent}>{name}</span>
        </Link>
      ))}
    </nav>
  );
}

export default Navbar;
