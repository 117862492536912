import { InputHTMLAttributes } from "react";
import { buildComplexClassName } from "../../utils";
import css from "./Input.module.css";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {}

function Input({ className, ...rest }: IInputProps) {
  return (
    <input className={buildComplexClassName(css.input, className)} {...rest} />
  );
}

export default Input;
