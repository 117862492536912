import { buildComplexClassName } from "../../utils";
import css from "./Spinner.module.css";
import { ReactComponent as SpinnerIcon } from "css.gg/icons/svg/spinner.svg";
import { ReactNode } from "react";

interface ISpinnerProps {
  className?: string;
  children?: ReactNode;
}

function Spinner({ className, children }: ISpinnerProps) {
  return (
    <div className={buildComplexClassName(css.wrapper, className)}>
      <div className={css.spinnerWrapper}>
        <SpinnerIcon className={css.spinner} />
      </div>

      <span className={css.label}>{children || "Загрузка..."}</span>
    </div>
  );
}

export default Spinner;
