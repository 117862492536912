import { buildComplexClassName } from "../../utils";
import css from "./Avatar.module.css";

interface IAvatarProps {
  fullName: string;
  className?: string;
}

function Avatar({ fullName, className }: IAvatarProps) {
  const [firstName, lastName] = fullName.split(" ");
  const firstTwoChars = !lastName
    ? firstName.substring(0, 2)
    : `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`;

  return (
    <div className={buildComplexClassName(css.wrapper, className)}>
      <span className={css.label}>{firstTwoChars}</span>
    </div>
  );
}

export default Avatar;
