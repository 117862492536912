import { ReactNode } from "react";
import { StrapiPhoto } from "../../strapi-types";
import { buildComplexClassName, buildSrcSet, formatDate } from "../../utils";
import css from "./StoryCard.module.css";
import noThumbnailImage from "./no-thumbnail.png";
import { ReactComponent as ExternalIcon } from "css.gg/icons/svg/external.svg";

const { REACT_APP_API_ENDPOINT } = process.env;

interface IStoryCardProps {
  title: ReactNode;
  createdAt: Date;
  author?: string;
  children?: ReactNode;
  thumbnail?: StrapiPhoto;
  typeLink?: boolean;
  className?: string;
}

function StoryCard({
  thumbnail,
  title,
  createdAt,
  author,
  children,
  className,
  typeLink = false,
}: IStoryCardProps) {
  const src = thumbnail
    ? `${REACT_APP_API_ENDPOINT}${thumbnail.url.substring(1)}`
    : noThumbnailImage;

  return (
    <div className={buildComplexClassName(css.wrapper, className)}>
      <div className={css.thumbnail}>
        {typeLink && (
          <div className={css.external}>
            <ExternalIcon />
          </div>
        )}
        <img
          src={src}
          alt={thumbnail?.caption || "Нет изображения"}
          srcSet={thumbnail ? buildSrcSet(thumbnail) : undefined}
        />
      </div>
      <div className={css.content}>
        <p className={css.date}>{formatDate(createdAt)}</p>
        <h3>{title}</h3>
        {author && (
          <p className={css.author}>
            Автор: <b>{author}</b>
          </p>
        )}
        {children}
      </div>
    </div>
  );
}

export default StoryCard;
