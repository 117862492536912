import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FindResult, StrapiPhoto, User, WithPagination } from "../strapi-types";

export type Story = {
  author: {
    data: FindResult<User> | null;
  };
  createdAt: string;
  location: { lat: number; lon: number };
  title: string;
  link: string | null;
  content: string;
  linkType: "none" | "external" | "portal" | null;
  thumbnail?: {
    data: FindResult<StrapiPhoto>;
  };
};
export type FoundStories = FindResult<Omit<Story, "content">>;
export type FoundStory = FindResult<Story>;

export const storiesAPI = createApi({
  reducerPath: "storiesAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}api`,
  }),
  endpoints: (builder) => ({
    getProjectsForMap: builder.query<
      WithPagination<FoundStories>,
      { start: number; limit: number }
    >({
      query: (params) =>
        `/stories?sort[0]=publishedAt:desc&pagination[start]=${params.start}&pagination[limit]=${params.limit}&populate[1]=thumbnail&populate[2]=author&fields[1]=createdAt&fields[2]=location&fields[3]=title&fields[4]=link&fields[5]=linkType&filters[$or][0][linkType][$null]=true&filters[$or][1][linkType]=none&filters[$or][2][linkType]=portal`,
    }),
    getProjects: builder.query<
      WithPagination<FoundStories>,
      { start: number; limit: number }
    >({
      query: (params) =>
        `/stories?sort[0]=publishedAt:desc&pagination[start]=${params.start}&pagination[limit]=${params.limit}&populate[1]=thumbnail&populate[2]=author&fields[1]=createdAt&fields[2]=location&fields[3]=title&fields[4]=link&fields[5]=linkType`,
    }),
    getStory: builder.query<{ data: FoundStory; meta: {} }, number>({
      query: (id) =>
        `/stories/${id}?populate[1]=thumbnail&populate[2]=author&fields[1]=createdAt&fields[2]=location&fields[3]=title&fields[4]=link&fields[5]=content`,
    }),
  }),
});

export const {
  useGetProjectsForMapQuery,
  useLazyGetProjectsQuery,
  useGetStoryQuery,
} = storiesAPI;
