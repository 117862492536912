import {
  DONATION_UID,
  GIFTS_UID,
  useGetStatisticsQuery,
} from "../../apis/donations";
import PageTitle from "../../atoms/PageTitle";
import DonationsTable from "../../organisms/DonationsTable";
import TinkoffPayment from "../../organisms/TinkoffPayment";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import css from "./DonationsPage.module.css";

const formatCurrency = new Intl.NumberFormat("ru", {
  style: "currency",
  currency: "RUB",
  currencyDisplay: "symbol",
});

const formatBigNumber = new Intl.NumberFormat("ru", {});

const giftWordWithSuffix = (number: number) => {
  const numberAsString = number.toString(10);
  const lastDigitString = numberAsString.slice(-1);
  const lastDigit = parseInt(lastDigitString, 10);

  if (lastDigit === 1) return "подарок";
  if (lastDigit > 1 && lastDigit < 5) return "подарка";

  return "подарков";
};

function DonationsPage() {
  const { isLoading, data, isError } = useGetStatisticsQuery();

  const donationPageConstants = data?.data || [];
  const donationItem = donationPageConstants.find(
    (e) => e.attributes.UniqueIdentificator === DONATION_UID
  );
  let moneyDonated = !donationItem
    ? null
    : parseFloat(donationItem.attributes.Value);
  if (Number.isNaN(moneyDonated)) moneyDonated = null;

  const giftsItem = donationPageConstants.find(
    (e) => e.attributes.UniqueIdentificator === GIFTS_UID
  );
  let giftsReceived = !giftsItem
    ? null
    : parseFloat(giftsItem.attributes.Value);
  if (Number.isNaN(giftsReceived)) giftsReceived = null;

  return (
    <NewDesignBasicPage>
      <main className={css.main}>
        <div className={css.content}>
          <PageTitle className={css.title}>Пожертвования</PageTitle>
          <p className={css.intro}>
            Проект получил{" "}
            {isLoading ? (
              <span className={css.skeleton} />
            ) : isError || moneyDonated === null ? (
              <em>нет данных</em>
            ) : (
              <strong title={String(moneyDonated)}>
                {formatCurrency.format(moneyDonated)}
              </strong>
            )}{" "}
            а так же{" "}
            {isLoading ? (
              <span className={css.skeleton} />
            ) : isError || giftsReceived === null ? (
              <em>нет данных</em>
            ) : (
              <strong title={String(giftsReceived)}>
                {formatBigNumber.format(giftsReceived)}{" "}
                {giftsReceived < 1000
                  ? giftWordWithSuffix(giftsReceived)
                  : "подарков"}
              </strong>
            )}
            .
          </p>
          <TinkoffPayment />
          <DonationsTable />
        </div>
      </main>
    </NewDesignBasicPage>
  );
}

export default DonationsPage;
