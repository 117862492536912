import { FC, ReactNode } from "react";
import Brand from "../../atoms/Brand";
import PageTitle from "../../atoms/PageTitle";
import { buildComplexClassName } from "../../utils";
import css from "./BrandedHeader.module.css";

const BrandedHeader: FC<{
  children: ReactNode;
  className?: string;
  description?: ReactNode;
}> = ({ children, className, description }) => {
  return (
    <div className={buildComplexClassName(css.wrapper, className)}>
      <Brand className={css.brand} noLink />
      <PageTitle
        className={buildComplexClassName(
          css.title,
          !!description && css.withDescription
        )}
      >
        {children}
      </PageTitle>
      {description && <p className={css.description}>{description}</p>}
    </div>
  );
};

export default BrandedHeader;
