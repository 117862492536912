import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../hooks/redux";

function ProtectedRoutePage() {
  const { isLoading, isUserAuthenticated } = useAppSelector((s) => ({
    isLoading: s.auth.isAuthProcess,
    isUserAuthenticated: s.auth.user !== undefined,
  }));

  if (isLoading) return <div>Загрузка...</div>;
  if (!isUserAuthenticated) return <Navigate to="/login" />;

  return <Outlet />;
}

export default ProtectedRoutePage;
