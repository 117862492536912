import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CollectionItem, WithPagination } from "../strapi-types";

export interface StrapiMedia {
  alternativeText: string;
  caption: string;
  createdAt: string;
  ext: string;
  formats: Record<
    "large" | "medium" | "small" | "thumbnail",
    | {
        ext: string;
        hash: string;
        height: number;
        mime: string;
        name: string;
        path: string | null;
        size: number;
        url: string;
        width: number;
      }
    | undefined
  >;
  hash: string;
  height: number;
  mime: string;
  name: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata?: unknown | null;
  size: number;
  updatedAt: string;
  url: string;
  width: number;
}

export interface NewsObject {
  content: string;
  createdAt: string;
  publishedAt: string;
  updatedAt: string;
  header: string;
  slug: string;
  image: {
    data: {
      id: number;
      attributes: StrapiMedia;
    };
  };
  excerpt?: string;
  manualDate?: string;
}

export interface ReducedNewsObject
  extends Omit<NewsObject, "content" | "createdAt"> {}

interface NewsObjectFromQueryAPI {
  entity: Omit<NewsObject, "image"> & { image: StrapiMedia };
  prevEntity: {
    slug: string;
    header: string;
    publishedAt: string;
    manualDate: string | null;
  } | null;
  nextEntity: {
    slug: string;
    header: string;
    publishedAt: string;
    manualDate: string | null;
  } | null;
}

export const newsAPI = createApi({
  reducerPath: "newsAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}api`,
  }),
  endpoints: (builder) => ({
    getManyNews: builder.query<
      WithPagination<CollectionItem<ReducedNewsObject>>,
      { start: number; limit: number }
    >({
      query: (params) =>
        `/more-news?sort=manualDate:desc&pagination[start]=${params.start}&pagination[limit]=${params.limit}&fields[0]=header&fields[1]=excerpt&fields[2]=slug&fields[3]=publishedAt&fields[4]=manualDate&populate=image`,
    }),
    getNewsByUrl: builder.query<NewsObjectFromQueryAPI | null, string>({
      query: (url) => `/news-by-slug/${url}`,
    }),
  }),
});

export const {
  useLazyGetManyNewsQuery,
  useGetManyNewsQuery,
  useGetNewsByUrlQuery,
} = newsAPI;
