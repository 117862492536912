import {
  Children,
  FC,
  PointerEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import css from "./VideoPlayer.module.css";
import { ReactComponent as PlayIcon } from "../../assets/icons/play-button.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/pause-button.svg";
import { ReactComponent as VolumeFullIcon } from "../../assets/icons/volume-full.svg";
import { ReactComponent as VolumeHalfIcon } from "../../assets/icons/volume-half.svg";
import { ReactComponent as VolumeMuteIcon } from "../../assets/icons/volume-mute.svg";
import { ReactComponent as MaximizeIcon } from "../../assets/icons/maximize.svg";
import { ReactComponent as MinimizeIcon } from "../../assets/icons/minimize.svg";
import { ReactComponent as SpinnerIcon } from "css.gg/icons/svg/spinner.svg";
import { buildComplexClassName, debounce } from "../../utils";
import tippy, { followCursor } from "tippy.js";

interface VideoPlayerProps {
  src: string;
  poster: string;
}

const RangeControl: FC<{
  videoDuration: number;
  bufferedRanges: [number, number][];
  movePointer: (newTime: number) => void;
  currentTime?: number;
}> = ({ currentTime = 0, videoDuration, bufferedRanges, movePointer }) => {
  const isInteractionStartedRef = useRef(false);
  const rangeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = rangeRef;
    if (!current) return;

    const instance = tippy(current, {
      content: "00:00",
      followCursor: true,
      plugins: [followCursor],
    });

    const handler = (event: MouseEvent) => {
      const { left: wrapperLeft, width: wrapperWidth } =
        current.getBoundingClientRect();
      const { pageX } = event;
      const secondsUnderCursor =
        ((pageX - wrapperLeft) / wrapperWidth) * videoDuration;

      const currentTimeHours = formatHours(secondsUnderCursor);
      const currentTimeMinutes = formatMinutes(secondsUnderCursor);
      const currentTimeSeconds = formatSeconds(secondsUnderCursor);
      const currentTimeViewParts: string[] = [];
      if (currentTimeHours) currentTimeViewParts.push(currentTimeHours);
      if (currentTimeMinutes) currentTimeViewParts.push(currentTimeMinutes);
      if (currentTimeSeconds) currentTimeViewParts.push(currentTimeSeconds);
      const currentTimeView = currentTimeViewParts.join(":");

      instance.setContent(currentTimeView);
    };
    current.addEventListener("mousemove", handler);
    return () => {
      instance.destroy();
      current.removeEventListener("mousemove", handler);
    };
  }, [videoDuration]);

  const pointerDownHandler: PointerEventHandler<HTMLDivElement> = (event) => {
    const { current: rangeElement } = rangeRef;
    if (!rangeElement) return;
    if (isInteractionStartedRef.current) return;

    event.preventDefault();
    isInteractionStartedRef.current = true;

    const { left: wrapperLeft, width: wrapperWidth } =
      rangeElement.getBoundingClientRect();
    const { pageX } = event;

    const left = pageX - wrapperLeft;
    const relativeLeft = Math.min(Math.max(left / wrapperWidth, 0), 1);

    movePointer(videoDuration * relativeLeft);
  };

  useEffect(() => {
    const { current: rangeElement } = rangeRef;
    if (!rangeElement) return;

    const pointerUpHandler = (event: PointerEvent) => {
      if (!isInteractionStartedRef.current) return;
      isInteractionStartedRef.current = false;

      event.preventDefault();
    };

    const pointerMoveHandler = (event: PointerEvent) => {
      if (!isInteractionStartedRef.current) return;

      event.preventDefault();

      const { left: wrapperLeft, width: wrapperWidth } =
        rangeElement.getBoundingClientRect();
      const { pageX } = event;

      const left = pageX - wrapperLeft;
      const relativeLeft = Math.min(Math.max(left / wrapperWidth, 0), 1);

      movePointer(videoDuration * relativeLeft);
    };

    document.addEventListener("pointerup", pointerUpHandler);
    document.addEventListener("pointermove", pointerMoveHandler);

    return () => {
      document.removeEventListener("pointerup", pointerUpHandler);
      document.removeEventListener("pointermove", pointerMoveHandler);
    };
  }, [movePointer, videoDuration]);

  return (
    <div className={buildComplexClassName(css.range, css.progress)}>
      <div
        className={css.rangeBar}
        ref={rangeRef}
        onPointerDown={pointerDownHandler}
      >
        <div
          className={css.rangePoint}
          style={{ left: `${(currentTime / videoDuration) * 100}%` }}
        />
      </div>
      <div
        className={buildComplexClassName(css.rangeItem, css.strong)}
        style={{
          left: 0,
          width: `${(currentTime / videoDuration) * 100}%`,
        }}
      />
      {Children.toArray(
        bufferedRanges.map(([start, end]) => (
          <div
            className={css.rangeItem}
            style={{
              left: `${(start / videoDuration) * 100}%`,
              width: `${((end - start) / videoDuration) * 100}%`,
            }}
          />
        ))
      )}
    </div>
  );
};

const SoundVolumeControl: FC<{
  soundVolume: number;
  setSoundVolume: (
    newSoundVolume: number | ((newValue: number) => number)
  ) => void;
}> = ({ soundVolume, setSoundVolume }) => {
  const rangeRef = useRef<HTMLDivElement>(null);
  const isInteractionStartedRef = useRef(false);
  const prevSoundVolumeRef = useRef(soundVolume);

  const pointerDownHandler: PointerEventHandler<HTMLDivElement> = (event) => {
    const { current: rangeElement } = rangeRef;
    if (!rangeElement) return;
    if (isInteractionStartedRef.current) return;

    event.preventDefault();
    isInteractionStartedRef.current = true;

    const { left: wrapperLeft, width: wrapperWidth } =
      rangeElement.getBoundingClientRect();
    const { pageX } = event;

    const left = pageX - wrapperLeft;
    const relativeLeft = Math.min(Math.max(left / wrapperWidth, 0), 1);

    setSoundVolume(relativeLeft);
  };

  useEffect(() => {
    const { current: rangeElement } = rangeRef;
    if (!rangeElement) return;

    const pointerUpHandler = (event: PointerEvent) => {
      if (!isInteractionStartedRef.current) return;
      isInteractionStartedRef.current = false;

      event.preventDefault();
    };

    const pointerMoveHandler = (event: PointerEvent) => {
      if (!isInteractionStartedRef.current) return;

      event.preventDefault();

      const { left: wrapperLeft, width: wrapperWidth } =
        rangeElement.getBoundingClientRect();
      const { pageX } = event;

      const left = pageX - wrapperLeft;
      const relativeLeft = Math.min(Math.max(left / wrapperWidth, 0), 1);
      setSoundVolume(relativeLeft);
    };

    document.addEventListener("pointerup", pointerUpHandler);
    document.addEventListener("pointermove", pointerMoveHandler);

    return () => {
      document.removeEventListener("pointerup", pointerUpHandler);
      document.removeEventListener("pointermove", pointerMoveHandler);
    };
  }, [setSoundVolume]);

  return (
    <div className={css.volume}>
      <button
        type="button"
        className={css.volumeButton}
        title={soundVolume === 0 ? "Включить звук" : "Выключить звук"}
        onClick={() => {
          setSoundVolume((p) => {
            if (p === 0) return prevSoundVolumeRef.current;
            prevSoundVolumeRef.current = p;
            return 0;
          });
        }}
      >
        {soundVolume === 0 ? (
          <VolumeMuteIcon className={css.volumeIcon} />
        ) : soundVolume < 0.5 ? (
          <VolumeHalfIcon className={css.volumeIcon} />
        ) : (
          <VolumeFullIcon className={css.volumeIcon} />
        )}
      </button>
      <div
        className={buildComplexClassName(css.range, css.volumeRange)}
        ref={rangeRef}
        onPointerDown={pointerDownHandler}
      >
        <div
          className={css.rangeBar}
          ref={rangeRef}
          onPointerDown={pointerDownHandler}
        >
          <div
            className={css.rangePoint}
            style={{ left: `${soundVolume * 100}%` }}
          />
        </div>
        <div
          className={buildComplexClassName(css.rangeItem, css.strong)}
          style={{
            width: `${100 * soundVolume}%`,
            left: 0,
          }}
        ></div>
      </div>
    </div>
  );
};

const formatHours = (seconds: number) => {
  const hours = Math.floor(seconds / 60 / 60);
  if (!hours) return undefined;
  if (hours < 10) return `0${hours}`;
  return `${hours}`;
};

const formatMinutes = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  if (minutes < 10) return `0${minutes}`;
  return `${minutes}`;
};

const formatSeconds = (sourceSeconds: number) => {
  const seconds = Math.round(sourceSeconds % 60);
  if (seconds < 10) return `0${seconds}`;
  return `${seconds}`;
};

function VideoPlayer({ src, poster }: VideoPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bufferedRanges, setBufferedRanges] = useState<[number, number][]>([]);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [soundVolume, setSoundVolume] = useState(1);
  const [isMaximized, setIsMaximized] = useState(false);
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const hideControlsTimerRef = useRef<number>();

  const currentTimeHours = formatHours(currentTime);
  const currentTimeMinutes = formatMinutes(currentTime);
  const currentTimeSeconds = formatSeconds(currentTime);
  const currentTimeViewParts: string[] = [];
  if (currentTimeHours) currentTimeViewParts.push(currentTimeHours);
  if (currentTimeMinutes) currentTimeViewParts.push(currentTimeMinutes);
  if (currentTimeSeconds) currentTimeViewParts.push(currentTimeSeconds);
  const currentTimeView = currentTimeViewParts.join(":");

  const durationHours = formatHours(videoDuration);
  const durationMinutes = formatMinutes(videoDuration);
  const durationSeconds = formatSeconds(videoDuration);
  const durationViewParts: string[] = [];
  if (durationHours) durationViewParts.push(durationHours);
  if (durationMinutes) durationViewParts.push(durationMinutes);
  if (durationSeconds) durationViewParts.push(durationSeconds);
  const durationView = durationViewParts.join(":");

  const keepControlsShowing = useCallback(() => {
    const { current } = wrapperRef;

    if (!isPlaying || !current) return;

    if (hideControlsTimerRef.current !== undefined)
      clearTimeout(hideControlsTimerRef.current);
    if (!current.classList.contains(css.showControlsOnPlaying))
      current.classList.add(css.showControlsOnPlaying);

    hideControlsTimerRef.current = window.setTimeout(() => {
      if (current.classList.contains(css.showControlsOnPlaying))
        current.classList.remove(css.showControlsOnPlaying);

      hideControlsTimerRef.current = undefined;
    }, 5000);
  }, [isPlaying]);

  const startPlaying = useCallback(async () => {
    const { current: videoElement } = videoElementRef;
    if (!videoElement) return;
    await videoElement.play();
    setIsPlaying(true);
  }, []);

  const stopPlaying = () => {
    const { current: videoElement } = videoElementRef;
    if (!videoElement) return;

    videoElement.pause();
    setIsPlaying(false);
  };

  const seekedHandler = () => {
    const { current: videoElement } = videoElementRef;
    if (!videoElement) return;

    const ranges: [number, number][] = [];
    for (let i = 0; i < videoElement.buffered.length; i++) {
      ranges.push([
        videoElement.buffered.start(i),
        videoElement.buffered.end(i),
      ]);
    }

    setBufferedRanges(ranges);
  };

  const metadataLoadedHandler = () => {
    const { current: videoElement } = videoElementRef;
    if (!videoElement) return;

    setVideoDuration(videoElement.duration);
    setSoundVolume(videoElement.volume);
  };

  const updateCurrentTimeHandler = () => {
    const { current: videoElement } = videoElementRef;
    if (!videoElement) return;

    setCurrentTime(videoElement.currentTime);
  };

  useEffect(() => {
    const { current: videoElement } = videoElementRef;
    if (!videoElement) return;

    // Try to autoplay (works if user comes to page in SPA mode)
    const tryToAutoPlay = async () => {
      try {
        await startPlaying();
      } catch (error) {}
    };
    tryToAutoPlay();
  }, [startPlaying]);

  const goForward = useCallback(() => {
    const { current: videoElement } = videoElementRef;
    if (!videoElement || videoDuration === undefined) return;
    const { currentTime } = videoElement;
    const newTime = Math.max(0, Math.min(currentTime + 10, videoDuration));
    videoElement.currentTime = newTime;
    setCurrentTime(newTime);
  }, [videoDuration]);

  const goBack = useCallback(() => {
    const { current: videoElement } = videoElementRef;
    if (!videoElement || videoDuration === undefined) return;
    const { currentTime } = videoElement;
    const newTime = Math.max(0, Math.min(currentTime - 10, videoDuration));
    videoElement.currentTime = newTime;
    setCurrentTime(newTime);
  }, [videoDuration]);

  const makeLouder = useCallback(() => {
    setSoundVolume((p) => Math.min(1, Math.max(p + 0.05, 0)));
  }, []);

  const makeQuieter = useCallback(() => {
    setSoundVolume((p) => Math.min(1, Math.max(p - 0.05, 0)));
  }, []);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowRight":
          event.preventDefault();
          goForward();
          break;
        case "ArrowLeft":
          event.preventDefault();
          goBack();
          break;
        case " ":
          event.preventDefault();
          isPlaying ? stopPlaying() : startPlaying();
          break;
        case "ArrowUp":
          event.preventDefault();
          makeLouder();
          break;
        case "ArrowDown":
          event.preventDefault();
          makeQuieter();
          break;
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [goBack, goForward, isPlaying, makeLouder, makeQuieter, startPlaying]);

  useEffect(() => {
    const handler = () => {
      setIsMaximized(!!document.fullscreenElement);
    };

    handler();
    document.addEventListener("fullscreenchange", handler);
    return () => {
      document.removeEventListener("fullscreenchange", handler);
    };
  }, []);

  useEffect(() => {
    const { current } = videoElementRef;
    if (!current) return;

    current.volume = soundVolume;
  }, [soundVolume]);

  return (
    <div
      className={css.wrapper}
      ref={wrapperRef}
      onPointerMove={debounce(keepControlsShowing, 20)}
    >
      <video
        poster={poster}
        onClick={isPlaying ? stopPlaying : startPlaying}
        onProgress={seekedHandler}
        onLoadedMetadata={metadataLoadedHandler}
        onTimeUpdate={updateCurrentTimeHandler}
        onWaiting={() => setIsLoading(true)}
        onCanPlay={() => setIsLoading(false)}
        className={css.viewport}
        ref={videoElementRef}
      >
        <source src={src} />
      </video>
      {isLoading && (
        <div className={css.loading}>
          <SpinnerIcon />
        </div>
      )}
      <div
        className={buildComplexClassName(
          css.controls,
          isPlaying && css.playing
        )}
      >
        <div className={css.duration}>
          <span className={css.durationCurrent}>
            {currentTimeView || "--:--"} /{" "}
          </span>
          {durationView || "--:--"}
        </div>
        <button
          title={isPlaying ? "Остановить просмотр" : "Продолжить просмотр"}
          type="button"
          className={css.play}
          onClick={isPlaying ? stopPlaying : startPlaying}
        >
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </button>
        <RangeControl
          movePointer={(newTime) => {
            const { current: videoElement } = videoElementRef;
            if (!videoElement) return;
            stopPlaying();
            videoElement.currentTime = newTime;
            updateCurrentTimeHandler();
          }}
          currentTime={currentTime}
          videoDuration={videoDuration}
          bufferedRanges={bufferedRanges}
        />
        <SoundVolumeControl
          soundVolume={soundVolume}
          setSoundVolume={setSoundVolume}
        />
        <button
          type="button"
          title="Полный экран"
          className={css.fullscreen}
          onClick={async () => {
            const { current } = wrapperRef;
            if (!current) return;

            if (document.fullscreenElement) document.exitFullscreen();

            try {
              await current.requestFullscreen();
            } catch (error) {
              console.error(error);
              alert("Ваш браузер не поддерживает полноэкранный режим");
            }
          }}
        >
          {isMaximized ? <MinimizeIcon /> : <MaximizeIcon />}
        </button>
      </div>
    </div>
  );
}

export default VideoPlayer;
