import { ButtonHTMLAttributes, ReactNode } from "react";
import { buildComplexClassName } from "../../utils";
import css from "./Button.module.css";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactNode;
  secondary?: boolean;
  contrast?: boolean;
  small?: boolean;
}

function Button({
  className,
  children,
  icon,
  secondary = false,
  contrast = false,
  small = false,
  ...rest
}: IButtonProps) {
  return (
    <button
      className={buildComplexClassName(
        css.button,
        secondary && css.secondary,
        contrast && css.contrast,
        small && css.small,
        className
      )}
      {...rest}
    >
      <span className={css.content}>{children}</span>
      {icon && <span className={css.icon}>{icon}</span>}
    </button>
  );
}

export default Button;
