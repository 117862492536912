import { lazy, Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FoundStories, useLazyGetProjectsQuery } from "../../apis/stories";
import Button from "../../atoms/Button";
import PageTitle from "../../atoms/PageTitle";
import Spinner from "../../atoms/Spinner";
import StoryCard from "../../molecules/StoryCard";
import PayBanner from "../../organisms/PayBanner";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import css from "./Stories.module.css";

const MapSection = lazy(async () => ({
  default: (await import("../../organisms/Map")).MapSection,
}));

const PAGINATION_STEP = 5;

function Stories() {
  const [fetchStories] = useLazyGetProjectsQuery();
  const [start, setStart] = useState(0);
  const [stories, setStories] = useState<FoundStories[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchStories({ start: start, limit: PAGINATION_STEP })
      .then(({ data: response }) => {
        if (!response) return;
        const { data, meta } = response || {};

        const { total } = meta.pagination;
        setIsLastPage(total <= start + PAGINATION_STEP);
        setStories((prevNews) => {
          if (start === 0) return data;
          return [...prevNews, ...data];
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchStories, start]);

  return (
    <NewDesignBasicPage>
      <PayBanner />
      <PageTitle className={css.title}>
        <span>Герои регионов</span>
      </PageTitle>
      <Suspense fallback={<Spinner />}>
        <MapSection />
      </Suspense>
      <div className={css.contentWrapper}>
        <div className={css.stories}>
          {stories.length > 0 ? (
            stories.map((entry) => {
              const story = entry.attributes;
              const {
                title,
                createdAt,
                thumbnail: thumbnailResult,
                author: authorResult,
              } = story;

              const thumbnail = thumbnailResult?.data.attributes;
              const author =
                authorResult.data?.attributes.fullName || "Неизвестно";

              if (
                entry.attributes.link &&
                entry.attributes.linkType === "external"
              )
                return (
                  <a
                    href={entry.attributes.link}
                    key={entry.id}
                    className={css.story}
                  >
                    <StoryCard
                      typeLink
                      title={title}
                      thumbnail={thumbnail}
                      author={author}
                      createdAt={new Date(createdAt)}
                    />
                  </a>
                );

              return (
                <Link
                  key={entry.id}
                  to={`/stories/${entry.id}`}
                  className={css.story}
                >
                  <StoryCard
                    title={title}
                    thumbnail={thumbnail}
                    author={author}
                    createdAt={new Date(createdAt)}
                  />
                </Link>
              );
            })
          ) : (
            <p>Историй пока нет</p>
          )}
          {!isLastPage && (
            <Button
              disabled={isLoading}
              className={css.center}
              type="button"
              onClick={() => setStart((p) => p + PAGINATION_STEP)}
            >
              Загрузить еще
            </Button>
          )}
        </div>
      </div>
    </NewDesignBasicPage>
  );
}

export default Stories;
