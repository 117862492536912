import { Link } from "react-router-dom";
import { ReducedNewsObject } from "../../apis/news";
import { buildComplexClassName, buildSrcSet, formatDate } from "../../utils";
import css from "./NewsCard.module.css";

const { REACT_APP_API_ENDPOINT } = process.env;

interface INewsCardProps extends ReducedNewsObject {
  className?: string;
}

function NewsCard({
  publishedAt,
  slug,
  image,
  header,
  excerpt,
  className,
  manualDate: manualDateSource,
}: INewsCardProps) {
  const manualDate = manualDateSource ? new Date(manualDateSource) : null;
  const publishedDate = new Date(publishedAt);
  const mediaData = image.data.attributes;

  return (
    <Link
      to={`/news/${slug}`}
      className={buildComplexClassName(css.wrapper, className)}
    >
      <div className={css.thumbnail}>
        <img
          src={`${REACT_APP_API_ENDPOINT}${mediaData.url.substring(1)}`}
          srcSet={buildSrcSet(mediaData)}
          alt={mediaData.alternativeText}
        />
      </div>
      <div className={css.content}>
        <p className={css.date}>{formatDate(manualDate || publishedDate)}</p>
        <h3>{header}</h3>
        {excerpt && <p className={css.excerpt}>{excerpt}</p>}
      </div>
    </Link>
  );
}

export default NewsCard;
