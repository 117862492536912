import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { registerUser } from "../../slices/auth";
import FormPage from "../../templates/FormPage";
import css from "./SignInPage.module.css";
import { ReactComponent as ErrorIcon } from "css.gg/icons/svg/close-o.svg";
import Spinner from "../../atoms/Spinner";

function SignInPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector((s) => s.auth.isAuthProcess);

  return (
    <FormPage title="Регистрация">
      <form
        className={css.form}
        onSubmit={async (event) => {
          try {
            event.preventDefault();
            const formData = new FormData(event.target as HTMLFormElement);
            const email = formData.get("email");
            const fullName = formData.get("fullName");
            const password = formData.get("password");
            const passwordConfirm = formData.get("password-confirm");

            if (!email || typeof email !== "string")
              throw new Error("email некорректный");
            if (!fullName || typeof fullName !== "string")
              throw new Error("Вы ввели некорректное имя");
            if (!password || typeof password !== "string")
              throw new Error("Вы ввели недопустимый пароль");
            if (!passwordConfirm || typeof passwordConfirm !== "string")
              throw new Error("Вы ввели недопустимый пароль");

            if (passwordConfirm !== password)
              throw new Error("Пароли должны совпадать");

            const { payload } = await dispatch(
              registerUser({ email, fullName, password })
            );

            if ("message" in (payload as any)) {
              if (
                (payload as any)?.message ===
                "Email or Username are already taken"
              ) {
                toast(
                  (t) => (
                    <>
                      <div style={{ marginRight: 5 }}>
                        Такой пользователь уже существует
                      </div>
                      <Button
                        small
                        onClick={() => {
                          navigate("/login");
                          toast.dismiss(t.id);
                        }}
                      >
                        Войти
                      </Button>
                    </>
                  ),
                  {
                    icon: (
                      <ErrorIcon
                        style={{ color: "var(--color-palette-danger)" }}
                      />
                    ),
                  }
                );
              } else {
                toast.error((payload as any)?.message || "Неизвестная ошибка");
              }
            } else {
              toast.success(
                `На адрес ${email} была отправлена ссылка для подтверждения регистрации`
              );
              navigate("/");
            }
          } catch (error) {
            toast.error((error as any)?.message || "Неизвестная ошибка");
          }
        }}
      >
        <Input required name="email" type="email" placeholder="Email" />
        <Input required name="fullName" type="text" placeholder="Имя" />
        <Input required name="password" type="password" placeholder="Пароль" />
        <Input
          required
          name="password-confirm"
          type="password"
          placeholder="Повторите пароль"
        />
        <Button disabled={isLoading} type="submit">
          {isLoading ? <Spinner /> : "Зарегистрироваться"}
        </Button>
        <Button onClick={() => navigate("/login")} type="submit" secondary>
          Войти
        </Button>
      </form>
    </FormPage>
  );
}

export default SignInPage;
