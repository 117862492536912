import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FindResult, StrapiPhoto, WithPagination } from "../strapi-types";

export type Medium = {
  thumbnail: { data: FindResult<StrapiPhoto> };
  title: string;
  url: string;
  description: string;
};
export type FoundMediumList = FindResult<Medium>;

export const mediumAPI = createApi({
  reducerPath: "mediumAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}api`,
  }),
  endpoints: (builder) => ({
    getMediumList: builder.query<
      WithPagination<FoundMediumList>,
      { start: number; limit: number }
    >({
      query: (params) =>
        `/mediums?sort[0]=createdAt:desc&pagination[start]=${params.start}&pagination[limit]=${params.limit}&populate[1]=thumbnail&fields[1]=title&fields[2]=url&fields[3]=description`,
    }),
  }),
});

export const { useLazyGetMediumListQuery } = mediumAPI;
