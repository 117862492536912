import { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Spinner from "../../atoms/Spinner";
import { useAppDispatch } from "../../hooks/redux";
import { manualLogin } from "../../slices/auth";
import { User } from "../../strapi-types";
import FormPage from "../../templates/FormPage";
import css from "./ForgotPasswordPage.module.css";

const { REACT_APP_API_ENDPOINT } = process.env;

enum Stage {
  EnterEmail = "f200e39b43180097b6c31dbb755e6108",
  EnterCode = "bcd9a92915d531f9150421a8155fe3c7",
}

function EnterEmailPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <FormPage
      title="Восстановление"
      description="Введите адрес почты аккаунта. Мы вышлем вам код подтверждения."
    >
      <form
        className={css.form}
        onSubmit={async (event) => {
          try {
            event.preventDefault();
            setIsLoading(true);
            const formData = new FormData(event.target as HTMLFormElement);
            const email = formData.get("email");
            if (!email || typeof email !== "string")
              throw new Error("Некорректный email");

            const response = await fetch(
              `${REACT_APP_API_ENDPOINT}api/auth/forgot-password`,
              {
                method: "POST",
                body: JSON.stringify({ email }),
                headers: {
                  "Content-type": "application/json",
                },
              }
            );
            const json = await response.json();
            if ("error" in json && "message" in json.error) {
              throw new Error(json.error.message);
            }

            toast("Письмо отправлено");
            navigate(
              `/forgot-password?stage=${Stage.EnterCode}&email=${email}`
            );
          } catch (error) {
            toast.error((error as any)?.message || "Неизвестная ошибка");
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <Input required name="email" type="email" placeholder="Email" />
        <Button type="submit" disabled={isLoading}>
          {isLoading ? <Spinner /> : "Прислать код"}
        </Button>
      </form>
    </FormPage>
  );
}

function EnterCodePage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const email = params.get("email");
  const codeFromUrl = params.get("code");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <FormPage
      title="Восстановление"
      description={
        email ? (
          <>
            Мы отправили письмо с кодом на email <b>{email}</b>
          </>
        ) : (
          "Мы отправили вам письмо с кодом. Вставьте код в поле ниже либо перейдите по ссылке в письме"
        )
      }
    >
      <form
        className={css.form}
        onSubmit={async (event) => {
          try {
            event.preventDefault();
            setIsLoading(true);
            const formData = new FormData(event.target as HTMLFormElement);
            const code = formData.get("code") || codeFromUrl;
            const password = formData.get("password");
            const passwordConfirmation = formData.get("confirm-password");

            if (!code || typeof code !== "string")
              throw new Error("Код указан неверно");
            if (!password || typeof password !== "string")
              throw new Error("Пароль указан неверно");
            if (password.length < 6)
              throw new Error("Пароль должен состоять минимум из 6 символов");
            if (passwordConfirmation !== password)
              throw new Error("Пароли должны совпадать");

            const response = await fetch(
              `${REACT_APP_API_ENDPOINT}api/auth/reset-password`,
              {
                method: "POST",
                body: JSON.stringify({ code, passwordConfirmation, password }),
                headers: {
                  "Content-type": "application/json",
                },
              }
            );
            const json = await response.json();
            if ("error" in json && "message" in json.error) {
              throw new Error(json.error.message);
            }

            if (!("jwt" in json)) throw new Error("Неизвестная ошибка");
            dispatch(manualLogin(json as { jwt: string; user: User }));
            toast.success("Пароль успешно сменен!");
            navigate("/");
          } catch (error) {
            toast.error((error as any)?.message || "Неизвестная ошибка");
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <Input
          required
          name="code"
          disabled={!!codeFromUrl}
          defaultValue={codeFromUrl || undefined}
          type="text"
          placeholder="Код"
        />
        <Input
          required
          name="password"
          type="password"
          placeholder="Новый пароль"
        />
        <Input
          required
          name="confirm-password"
          type="password"
          placeholder="Повторите новый пароль"
        />
        <Button type="submit" disabled={isLoading}>
          {isLoading ? <Spinner /> : "Обновить"}
        </Button>
        {email && !codeFromUrl && (
          <Link to={`/forgot-password?stage=${Stage.EnterEmail}`}>
            Неверный email
          </Link>
        )}
      </form>
    </FormPage>
  );
}

const parseStage = (s: string) => {
  switch (s) {
    case Stage.EnterCode:
      return Stage.EnterCode;
    case Stage.EnterEmail:
      return Stage.EnterEmail;
    default:
      return Stage.EnterEmail;
  }
};

export default function Provider() {
  const [params] = useSearchParams();
  const stage = parseStage(params.get("stage") || Stage.EnterEmail);

  switch (stage) {
    case Stage.EnterCode:
      return <EnterCodePage />;
    case Stage.EnterEmail:
      return <EnterEmailPage />;
  }
}
