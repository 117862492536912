import ReactMarkdown from "react-markdown";
import { Link, useParams } from "react-router-dom";
import { useGetNewsByUrlQuery } from "../../apis/news";
import { buildSrcSet, formatDate } from "../../utils";
import css from "./NewsPage.module.css";
import { ReactComponent as ArrowRightIcon } from "css.gg/icons/svg/arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "css.gg/icons/svg/arrow-left.svg";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import PageTitle from "../../atoms/PageTitle";

const { REACT_APP_API_ENDPOINT } = process.env;

function NewsPage() {
  const { newsUrl } = useParams();
  if (!newsUrl) throw new Error("Страница не соответствует пути");

  const { data, isLoading } = useGetNewsByUrlQuery(newsUrl);
  const { entity, prevEntity, nextEntity } = data || {};
  const date = formatDate(
    new Date(entity?.manualDate || entity?.publishedAt || "")
  );

  return (
    <NewDesignBasicPage>
      <article className={css.wrapper}>
        <PageTitle className={css.title}>{entity?.header}</PageTitle>
        <div className={css.inner}>
          {isLoading ? (
            <span>Загрузка...</span>
          ) : !entity ? (
            <span>Ошибка загрузки</span>
          ) : (
            <>
              {entity.excerpt && (
                <p className={css.excerpt}>{entity.excerpt}</p>
              )}
              <div className={css.thumbnail}>
                <img
                  src={`${REACT_APP_API_ENDPOINT}${entity.image.url.substring(
                    1
                  )}`}
                  alt={entity.image.caption}
                  srcSet={buildSrcSet(entity.image)}
                />
              </div>

              <ReactMarkdown className={css.content}>
                {entity.content}
              </ReactMarkdown>
              <p className={css.date}>{date}</p>
              <div className={css.navigation}>
                {prevEntity && (
                  <Link
                    className={css.navigationLeft}
                    to={`/news/${prevEntity.slug}`}
                  >
                    <span>{prevEntity.header}</span>
                    <ArrowLeftIcon />
                  </Link>
                )}
                {nextEntity && (
                  <Link
                    className={css.navigationRight}
                    to={`/news/${nextEntity.slug}`}
                  >
                    <span>{nextEntity.header}</span>
                    <ArrowRightIcon />
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
      </article>
    </NewDesignBasicPage>
  );
}

export default NewsPage;
