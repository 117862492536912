import { Link } from "react-router-dom";
import css from "./Footer.module.css";

function Footer() {
  return (
    <footer className={css.wrapper}>
      <div className={css.content}>
        <div className={css.legal}>
          <h3 className={css.smallTitle}>
            Пользуясь сервисом вы принимаете следующие правила:
          </h3>
          <Link className={css.link} to="/user-agreement">
            Пользовательское соглашение
          </Link>
          <Link className={css.link} to="/data-processing-policy">
            Политика в отношении обработки персональных данных
          </Link>
          <Link className={css.link} to="/privacy-policy">
            Политика конфиденциальности
          </Link>
        </div>
        <div className={css.contacts}>
          <h3 className={css.title}>Наши контакты</h3>
          <a
            className={css.link}
            target="_blank"
            rel="noreferrer"
            href="mailto:idemvmeste@mail.ru"
          >
            idemvmeste@mail.ru
          </a>
          <a
            className={css.link}
            target="_blank"
            rel="noreferrer"
            href="tel:+79935554545"
          >
            +7 (993) 555-45-45
          </a>
          <a
            className={css.link}
            target="_blank"
            rel="noreferrer"
            href="https://vk.com/geroi.regionov"
          >
            Вконтакте
          </a>
          <a
            className={css.link}
            target="_blank"
            rel="noreferrer"
            href="https://ok.ru/group/62370450964699"
          >
            Одноклассники
          </a>
          <a
            className={css.link}
            target="_blank"
            rel="noreferrer"
            href="https://t.me/idemvmeste"
          >
            Телеграм
          </a>
          <a
            className={css.link}
            target="_blank"
            rel="noreferrer"
            href="https://rutube.ru/channel/24759850/"
          >
            RuTube
          </a>
          <a
            className={css.link}
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCa2OKEPe7_eDK_4TNI-ZGyQ"
          >
            YouTube
          </a>
          <a
            className={css.link}
            target="_blank"
            rel="noreferrer"
            href="https://zen.yandex.ru/geroyregionov"
          >
            Яндекс.Дзен
          </a>
        </div>
        <p className={css.label}>
          Автономная некоммерческая организация по развитию образовательной,
          культурной и исследовательской деятельности "Поколение уверенного
          будущего" совместно с Региональной общественной организацией
          патриотического воспитания молодежи "Ратник"
        </p>
        <p className={css.copyright}>
          ©2017 - 2022 АНО "Поколение уверенного будущего"
        </p>
      </div>
    </footer>
  );
}

export default Footer;
