import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { donationsAPI } from "./apis/donations";
import { mediaAPI } from "./apis/media";
import { mediumAPI } from "./apis/medium";
import { newsAPI } from "./apis/news";
import { storiesAPI } from "./apis/stories";
import authReducer from "./slices/auth";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [newsAPI.reducerPath]: newsAPI.reducer,
    [storiesAPI.reducerPath]: storiesAPI.reducer,
    [mediumAPI.reducerPath]: mediumAPI.reducer,
    [mediaAPI.reducerPath]: mediaAPI.reducer,
    [donationsAPI.reducerPath]: donationsAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      newsAPI.middleware,
      storiesAPI.middleware,
      mediumAPI.middleware,
      mediaAPI.middleware,
      donationsAPI.middleware
    ),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
