import Brand from "../../atoms/Brand";
import Account from "../Account";
import Navbar from "../../molecules/Navbar";
import css from "./Header.module.css";
import { ReactComponent as MenuIcon } from "css.gg/icons/svg/menu.svg";
import { ReactComponent as CloseIcon } from "css.gg/icons/svg/close.svg";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { buildComplexClassName } from "../../utils";
import twelveOnly from "../../assets/12-plus.svg";

function Header() {
  const [isOpened, setIsOpened] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsOpened(false);
  }, [location]);

  return (
    <>
      <header className={css.mobile}>
        <Brand className={css.brand} />
        <button
          type="button"
          title={isOpened ? "Закрыть меню" : "Открыть меню"}
          onClick={() => setIsOpened((p) => !p)}
          className={css.button}
        >
          {isOpened ? <CloseIcon /> : <MenuIcon />}
        </button>
      </header>
      <header
        className={buildComplexClassName(css.menu, isOpened && css.opened)}
      >
        <div className={css.menuContainer}>
          <Brand className={css.brand} />
          <Navbar className={css.navbar} />
          {process.env.REACT_APP_BUILD_TARGET !== "reduced" && <Account />}
          <div className={css.label}>
            <p>
              Автономная некоммерческая организация по развитию образовательной,
              культурной и исследовательской деятельности "Поколение уверенного
              будущего" совместно с Региональной общественной организацией
              патриотического воспитания молодежи "Ратник"
            </p>
          </div>

          <div className={css.disclaimer}>
            <img alt="12+" src={twelveOnly} />
          </div>
        </div>
      </header>
      <button
        type="button"
        title="Закрыть меню"
        aria-hidden={isOpened}
        onClick={() => setIsOpened(false)}
        className={buildComplexClassName(css.overlap, isOpened && css.opened)}
      ></button>
    </>
  );
}

export default Header;
