import { buildComplexClassName } from "../../utils";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg";
import css from "./Pagination.module.css";
import { Children } from "react";

interface IPaginationProps {
  current: number;
  count: number;
  onSelect: (selected: number) => void;
  className?: string;
  disabled?: boolean;
}

function buildPagination(c: number, m: number): (number | null)[] {
  if (m < 7) return Array.from({ length: m }, (_, i) => i);

  if (c < 2 || c > m - 3) {
    return [0, 1, 2, null, m - 3, m - 2, m - 1];
  }

  if (c === 2) {
    return [0, 1, 2, 3, null, m - 2, m - 1];
  }

  if (c > 2 && c < m - 3) {
    return [0, null, c - 1, c, c + 1, null, m - 1];
  }

  return [0, 1, null, m - 4, m - 3, m - 2, m - 1];
}

function Pagination({
  current,
  count,
  className,
  onSelect,
  disabled = false,
}: IPaginationProps) {
  const paginationReferenceArray = buildPagination(current, count);

  return (
    <div className={buildComplexClassName(css.wrapper, className)}>
      <button
        className={css.sideButton}
        type="button"
        onClick={() => onSelect(current - 1)}
        disabled={current === 0 || disabled}
      >
        <ArrowLeftIcon className={css.icon} />
      </button>
      {Children.toArray(
        paginationReferenceArray.map((el) => {
          if (el === null) return <span className={css.dots}>...</span>;

          return (
            <button
              className={css.pageButton}
              type="button"
              onClick={() => onSelect(el)}
              disabled={current === el || disabled}
            >
              {el + 1}
            </button>
          );
        })
      )}
      <button
        className={css.sideButton}
        type="button"
        onClick={() => onSelect(current + 1)}
        disabled={current === count - 1 || disabled}
      >
        <ArrowRightIcon className={css.icon} />
      </button>
    </div>
  );
}

export default Pagination;
