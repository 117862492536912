import { lazy, Suspense, useRef } from "react";
import css from "./NewStoryPage.module.css";
import type { Editor, TinyMCE } from "tinymce";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import { useState } from "react";
import { useAppSelector } from "../../hooks/redux";
import { useNavigate } from "react-router-dom";
import NewDesignBasicPage from "../../templates/NewDesignBasicPage";
import StoriesTabsHeader from "../../molecules/StoriesTabsHeader";
import Spinner from "../../atoms/Spinner";
import toast from "react-hot-toast";

declare const tinymce: TinyMCE;

const BundledEditor = lazy(() => import("../../TinyMCE"));
const LocationPicker = lazy(() => import("../../organisms/LocationPicker"));

const { REACT_APP_API_ENDPOINT } = process.env;

function NewStoryPage() {
  const navigate = useNavigate();
  const jwt = useAppSelector((s) => s.auth.jwt);
  const editorRef = useRef<Editor>();
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState<{ lat: number; lon: number }>();

  return (
    <NewDesignBasicPage>
      <StoriesTabsHeader className={css.header} />
      <form
        onSubmit={async (event) => {
          setIsLoading(true);
          event.preventDefault();

          try {
            if (!jwt) throw new Error("Войдите в свой аккаунт.");
            const formData = new FormData(event.target as HTMLFormElement);

            if (!location) throw new Error("Выберите точку на карте.");
            formData.set("location", JSON.stringify(location));

            const title = formData.get("title");
            if (!title || typeof title !== "string")
              throw new Error("Заголовок не установлен.");

            const thumbnail = formData.get("thumbnail");
            if (!thumbnail || !(thumbnail instanceof File))
              throw new Error("Установите обложку.");

            const { current } = editorRef;
            if (!current) throw new Error("Ошибка редактора. Повторите позже.");

            const content = current.getContent();
            if (!content) throw new Error("Напишите историю.");

            formData.set("content", content);

            const response = await fetch(
              `${REACT_APP_API_ENDPOINT}api/my-story`,
              {
                method: "POST",
                body: formData,
                headers: {
                  Authorization: `Bearer ${jwt}`,
                },
              }
            );

            const json = await response.json();
            if ("error" in json) throw new Error(json.error);
            navigate("/profile/stories");
          } catch (error) {
            toast.error((error as Error).message);
          } finally {
            setIsLoading(false);
          }
        }}
        className={css.wrapper}
      >
        <div className={css.inner}>
          <label className={css.name}>
            <span className={css.label}>Название</span>
            <Input
              className={css.input}
              required
              type="text"
              name="title"
              placeholder="Новая история"
            />
          </label>
          <Suspense fallback={<Spinner>Редактор загружается</Spinner>}>
            <label className={css.story}>
              <span className={css.label}>История</span>
              <BundledEditor
                onInit={(_, editor) => (editorRef.current = editor)}
                init={{
                  language: "ru",
                  language_url: "/tinymce-ru-translation.js",
                  height: 500,
                  menubar: false,
                  image_title: true,
                  automatic_uploads: true,
                  file_picker_types: "image",
                  file_picker_callback: (cb, value, meta) => {
                    const input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");

                    input.addEventListener("change", (e) => {
                      const file = (e.target as any)?.files[0];

                      const reader = new FileReader();
                      reader.addEventListener("load", () => {
                        /*
                    Note: Now we need to register the blob in TinyMCEs image blob
                    registry. In the next release this part hopefully won't be
                    necessary, as we are looking to handle it internally.
                  */
                        const id = "blobid" + new Date().getTime();
                        const blobCache =
                          tinymce.activeEditor?.editorUpload.blobCache;

                        if (!blobCache) throw new Error("Cannot init tinyMCE");
                        const base64 = (reader.result as string)?.split(",")[1];
                        const blobInfo = blobCache?.create(id, file, base64);
                        blobCache.add(blobInfo);

                        /* call the callback and populate the Title field with the file name */
                        cb(blobInfo.blobUri(), { title: file.name });
                      });
                      reader.readAsDataURL(file);
                    });

                    input.click();
                  },
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "preview",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | image",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </label>
          </Suspense>
          <label className={css.thumbnail}>
            <span>Обложка:</span>
            <Input
              type="file"
              name="thumbnail"
              accept="image/jpeg, image/png"
              required
            />
          </label>
          <Suspense fallback={<Spinner>Карта загружается</Spinner>}>
            <label className={css.map}>
              <span className={css.label}>Место</span>
              <p className={css.label}>
                Выберите точку на карте, которая ассоциируется с вашей историей.
                Место рождения героя или место главного события
              </p>
              <LocationPicker onChange={setLocation} />
            </label>
          </Suspense>
          <div className={css.actions}>
            <Button disabled={isLoading} type="submit">
              Опубликовать
            </Button>
            <Button
              secondary
              type="reset"
              onClick={() => {
                editorRef.current?.resetContent();
              }}
            >
              Очистить
            </Button>
          </div>
        </div>
      </form>
    </NewDesignBasicPage>
  );
}

export default NewStoryPage;
